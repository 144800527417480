// React
import React, { useState, useEffect, useRef } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"

// API
import Axios from "axios"
import { DeliveryOrderApi } from "../../../../api"

// Component
import { Alert, ActionButton, BackButton, DataStatus } from "../../../../components"
import { Card, Col, Row } from "react-bootstrap"

// React Print
import { useReactToPrint } from "react-to-print"
import PrintDeliveryOrder from './PrintDeliveryOrder'

// Page Component
import { 
  InfoCatatanSection, InfoDOSection, InfoSection, TableSection
} from "./Section"

const DetailDeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  // data
  const [data, setData] = useState([])
  // menampung data yang akan dihapus

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  })

  const { id } = useParams()
  const location = useLocation()

  // request data dari server
  const getData = () => {
    setIsLoading(true)
    Axios.all([DeliveryOrderApi.getFormEdit(id)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data)
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        })
        setShowAlert(true)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Delivery Order")

    // jalankan function request data ke server
    getData()
    return () => {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle])

  const refPrint = useRef()
  const reactToPrint = useReactToPrint({
    content: () => refPrint.current,
  })
  const handlePrint = () => {
    reactToPrint()
  }

  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Delivery Order</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {data.stakeholder.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    )
  }

  const TambahData = () => {
    return (
      <>
        <InfoSection data={data} />
        <InfoDOSection data={data} />
        <TableSection data={data} type='detail' />
        <InfoCatatanSection data={data} />

        <div className="d-flex justify-content-end">
          <div style={{ display: "none" }}>
            <PrintDeliveryOrder ref={refPrint} idRef={id} data={data} />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data !== undefined ? (
        <>
          <Card>
            <Card.Header className="d-flex flex-row justify-content-between align-items-center">
              <b>Detail Delivery Order</b>
              <div>
                <ActionButton className='mx-1 mb-1' text="Cetak" onClick={handlePrint} />
                <BackButton onClick={() => history.push("/inventory/transaksi/delivery-order", { ...location?.state })} />
              </div>
            </Card.Header>
            <Card.Body>
              <Alert
                show={showAlert}
                showCloseButton={true}
                variant={alertConfig.variant}
                text={alertConfig.text}
                onClose={() => setShowAlert(false)}
              />

              <TambahData />
            </Card.Body>
          </Card>

          <FormCard />
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </>
  )
}

export default DetailDeliveryOrder
