// React
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

// Component
import { Row, Col } from "react-bootstrap"
import {
  CRUDLayout, Alert, DataStatus, InputSearch,
  ActionButton, Pagination, Table, Th, Td,
  ThFixed, TdFixed, Select, BackButton, FilterButton
} from "components"
import { DateConvert, TableNumber } from "utilities"

// API
import Axios from "axios"
import { SuratJalanApi } from "api"
import { ModalFilterDO } from "../Section"

// Icon
import { IoAddOutline } from 'react-icons/io5'

const ListDeliveryOrderSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [modalFilter, setModalFilter] = useState(false)
  const [dataDeliveryOrder, setDataDeliveryOrder] = useState([])
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.do?.filter?.active,
      tgl_delivery_order_mulai: location?.state?.do?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: location?.state?.do?.filter?.tgl_delivery_order_selesai,
      tgl_batas_waktu_mulai: location?.state?.do?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: location?.state?.do?.filter?.tgl_batas_waktu_selesai,
      customer: location?.state?.do?.filter?.customer,
      gudang: location?.state?.do?.filter?.gudang,
    },
    pagination: {
      page: location?.state?.do?.filter?.page ?? "1",
      dataLength: location?.state?.do?.filter?.dataLength ?? "10",
      totalPage: location?.state?.do?.filter?.totalPage ?? "1",
      dataCount: location?.state?.do?.filter?.dataCount ?? "0",
    }
  })
  const [dataGudang, setDataGudang] = useState([])
  const [selectedGudang, setSelectedGudang] = useState("")
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  
  const getInitialData = () => {
    setIsPageLoading(true)

    Axios.all([
      SuratJalanApi.getDO({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
        tgl_delivery_order_selesai: dataFilter?.filter?.tgl_delivery_order_selesai,
        tgl_batas_waktu_mulai: dataFilter?.filter?.tgl_batas_waktu_mulai,
        tgl_batas_waktu_selesai: dataFilter?.filter?.tgl_batas_waktu_selesai,
        customer: dataFilter?.filter?.customer,
        gudang: dataFilter?.filter?.gudang,
      }),
      SuratJalanApi.getDropdownGudang()
    ])
    .then(Axios.spread((dataDO, gudang) => {
        setDataDeliveryOrder(dataDO?.data?.data ?? [])
        setDataGudang(gudang.data.data)
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: dataDO?.data?.data_count,
            totalPage: dataDO?.data?.total_page,
          }
        })
      }))
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    setNavbarTitle("Surat Jalan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    // selectedGudang,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.tgl_batas_waktu_mulai,
    dataFilter?.filter?.tgl_batas_waktu_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.gudang,
  ])

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
  
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_barang} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg.nama_barang} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => { setisReadMoreClick((prev) => !prev)}}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ): ("")}
        </>
      );
    }
    const DataTable = () => (
      <>
        <b style={{ fontSize: "14px" }}>List Data Delivery Order Yang Siap Dibuatkan Surat Jalan</b>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Delivery Order</ThFixed>
              <Th>Customer</Th>
              <Th>Gudang</Th>
              <Th style={{minWidth:"300px"}}>Item Barang</Th>
              {/* <ThFixed>Tgl. Batas Waktu</ThFixed> */}
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>
          <tbody>
            {dataDeliveryOrder.map((val, index) => (
              <tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_delivery_order ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_delivery_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                {/* <Td><ul className="pl-3">{val.barang_jadi.map((item, index) => <li key={index}>{item.nama_barang}</li>)}</ul></Td> */}
                <Td>{val.barang_jadi ? val.barang_jadi.length > 0 ? <ItemBarangCollapse data={val.barang_jadi} /> : "-" : "-"}</Td>
                {/* <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</TdFixed> */}
                <TdFixed>
                  <ActionButton 
                    size="sm"
                    variant="primary"
                    text={<IoAddOutline />}
                    onClick={() => history.push("/inventory/transaksi/surat-jalan/tambah/" + val.id_delivery_order, { ...location?.state, do: dataFilter })}
                  />
                </TdFixed>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )

    if (!dataDeliveryOrder || dataDeliveryOrder.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <div className="mt-2">
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </div>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch size="sm" value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          {/* <div className="d-flex align-items-end"> */}
            {/* {!isPageLoading && 
              <Select 
                label="Gudang"
                defaultValue={selectedGudang}
                onChange={e => {
                  setSelectedGudang(e.target.value)
                  setPaginationConfig({
                    ...paginationConfig,
                    page: 1
                  })
                }}
              >
                <option value="">Semua Gudang</option>
                {dataGudang.map((val, index) => <option value={val.id_gudang}>{val.nama_gudang}</option>)}
              </Select>
            } */}
            {/* <div className="mb-1 ml-1"> */}
              {/* <BackButton 
                size="sm"
                onClick={() => history.push("/inventory/transaksi/surat-jalan", { ...location?.state, do: dataFilter })}
              /> */}
              <BackButton onClick={() => history.push("/inventory/transaksi/surat-jalan", { ...location?.state, do: dataFilter })} />
            {/* </div> */}
          {/* </div> */}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      
      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

      {modalFilter === true && (
        <ModalFilterDO
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListDeliveryOrderSuratJalan
