const DecimalConvert = (value) => {
    const getWithComa = (number) => {
      const convertToNumber = parseFloat(number);
      const checkConvert =
        convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));
  
      return new Intl.NumberFormat("id-ID").format(checkConvert);
    };
  
    return {
      getWithComa: getWithComa(value),
    };
  };
  
  export default DecimalConvert;
  