// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  BackButton,
  TdFixed,
  DataStatus,
  Tr,
  ThFixed,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  FilterButton
} from "../../../../components";
import { PageNumber, DateConvert } from "../../../../utilities";
import { Button } from "react-bootstrap";
import { ModalFilter } from "./Section Purchase Order";
import { IoAddOutline } from "react-icons/io5";

// API
import { PenerimaanBarangApi } from "../../../../api";

const ListPurchaseOrder = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const title = "List Item Purchase Order";

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [showModalFilter, setShowModalFilter] = useState(false);

  // data jenis
  const [data, setData] = useState([
    {
      qty_diterima: "0.00",
      qty_order: "0.00",
      qty_sisa: "0.00",
    },
  ]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.po?.filter?.active,
      tgl_purchase_order_mulai: location?.state?.po?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: location?.state?.po?.filter?.tgl_purchase_order_selesai,
      vendor: location?.state?.po?.filter?.vendor,
      item_barang: location?.state?.po?.filter?.item_barang,
    },
    pagination: {
      page: location?.state?.penerimaan?.filter?.page ?? "1",
      dataLength: location?.state?.penerimaan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.penerimaan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.penerimaan?.filter?.dataCount ?? "0",
    },
  });

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    PenerimaanBarangApi.getPagePO({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: dataFilter?.filter?.tgl_purchase_order_selesai,
      vendor: dataFilter?.filter?.vendor,
      item_barang: dataFilter?.filter?.item_barang,
    })
      .then((res) => {
        setData(res.data.data);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch((err) => alert(err.response.data.message))
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({ variant: "primary", text: `Hasil Pencarian :${searchKey}` });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

// SEARCH HANDLER
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchKey(e.target.value)
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setShowAlert(key ? true : false)
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    setIsLoading(true);

    // nilai dari form search
    const key = searchKey;

    // lakukan search data ke server pada kode dibawah [

    PenerimaanBarangApi.searchPO(key)
      .then((res) => {
        setData(res.data.data);
        // setTotalPage(res.data.total_page);
      })
      .catch((err) => alert(err.response.data.message))
      .finally(() => {
        setIsSearching(true);
        setIsLoading(false);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle, 
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.vendor,
    dataFilter?.filter?.item_barang,
  ]);

  // Tabel
  const Table = () => (
    <>
      <span style={{ fontSize: "14px" }}>
        <b>Po Yang Siap Dibuatkan Penerimaan Barang</b>
      </span>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Purchase Order</ThFixed>
            <Th>Vendor</Th>
            <Th>Item Barang</Th>
            <Th width={120}>Qty. Purchase Order</Th>
            <Th width={100}>Qty. Diterima</Th>
            <Th width={100}>Qty. Sisa</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={val.id_purchase_order_detail}>
              <TdFixed textCenter>
                {PageNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </TdFixed>
              <TdFixed>
                <div className="text-left">{val.tgl_purchase_order ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY : "-"}</div>
                <div className="text-left">{val.no_purchase_order ?? "-"}</div>
              </TdFixed>
              <Td>{val.nama_vendor}</Td>
              <Td>
                {/* {val.dimensi === "3"
                  ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                  : val.dimensi === "0"
                    ? val.nama_item
                    : val.dimensi === "1"
                      ? `${val.nama_item} (${val.pj})`
                      : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : "-"} */}
                {val.nama_item}
              </Td>
              <Td>
                <div className="text-right">
                  {val.qty_order ? val.qty_order.replace(".", ",") : "0,00"}
                </div>
              </Td>
              <Td>
                <div className="text-right">
                  {val.qty_diterima ? val.qty_diterima.replace(".", ",") : "0,00"}
                </div>
              </Td>
              <Td>
                <div className="text-right">
                  {val.qty_sisa ? val.qty_sisa.replace(".", ",") : "0,00"}
                </div>
              </Td>
              <TdFixed>
                <Button
                  // variant="primary"
                  variant={
                    parseInt(val.qty_diterima ?? 0) === 0
                      ? "primary"
                      : parseFloat(val.qty_diterima) === parseFloat(val.qty_order)
                      ? "success"
                      : "warning"
                  }
                  size="sm"
                  onClick={() => {
                    history.push(
                      `/inventory/transaksi/penerimaan-barang/tambah/${val.id_purchase_order_detail}`,
                      { ...location?.state, po: dataFilter }
                    );
                  }}
                >
                  <IoAddOutline />
                </Button>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchKey} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/inventory/transaksi/penerimaan-barang", { ...location?.state, po: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false);
          // CLEAR HISTORY LOCATION
          history.replace("/transaksi/analisa-barang-jadi", { registerAlertConfig: undefined });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPurchaseOrder;
