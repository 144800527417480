import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import * as Yup from "yup"
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { KartuStockMaterialApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData, getData }) => {
// STATE DATA
    // const option = { label: 'Semua Data', value: undefined }
    const [dropdownFilter, setDropdownFilter] = useState({
		kelompok: [],
		jenis: [],
		barang: [],
		gudang: []
    })
	const [loading, setLoading] = useState(true)
	const [loadingJenis, setLoadingJenis] = useState(false)
	const [loadingBarang, setLoadingBarang] = useState(false)
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
            KartuStockMaterialApi.getDropdownKelompok(),
            KartuStockMaterialApi.getDropdownGudang(),
            data.id_kelompok && KartuStockMaterialApi.getDropdownJenis(data.id_kelompok),
            data.id_jenis && KartuStockMaterialApi.getDropdownMaterial(data.id_jenis)
        ]).then(Axios.spread((kelompok, gudang, jenis, barang) => {
            
            const mappingKelompok = kelompok?.data?.data ? kelompok.data.data.map(val => ({label: val.nama_kelompok, value: val.id_kelompok})) : []
            const mappingGudang = gudang?.data?.data ? gudang.data.data.map(val => ({label: val.nama_gudang, value: val.id_gudang})) : []
            const mappingJenis = jenis?.data?.data ? jenis.data.data.map(val => ({label: val.nama_jenis, value: val.id_jenis})) : []
            const mappingBarang = barang?.data?.data ? barang.data.data.map(val => ({label: val.nama_item, value: val.id_item_buaso})) : []

            setDropdownFilter({
                kelompok: [ ...mappingKelompok],
                gudang: [ ...mappingGudang],
                jenis: data.id_kelompok ? [ ...mappingJenis] : [],
                barang: data.id_jenis ? [ ...mappingBarang] : []
            })
        })).finally(() => setLoading(false))
    }
    const getDataJenis = (value, values, setValues) => {
		setValues({
			...values,
			id_kelompok: value,
			id_jenis: null,
			id_barang: null
		})

		setLoadingJenis(true)
		KartuStockMaterialApi.getDropdownJenis(value).then(jenis => {
            const mappingJenis = jenis?.data?.data ? jenis.data.data.map(val => ({label: val.nama_jenis, value: val.id_jenis})) : []

			setDropdownFilter({
				...dropdownFilter,
				jenis: [ ...mappingJenis],
				barang: []
			})
		}).catch(() => {
			setDropdownFilter({
				...dropdownFilter,
				jenis: [],
				barang: []
			})
		}).finally(() => setLoadingJenis(false))
	}
    const getDataBarang = (value, values, setValues) => {
		setValues({
			...values,
			id_jenis: value,
			id_barang: null
		})

		setLoadingBarang(true)
		KartuStockMaterialApi.getDropdownMaterial(value).then(barang => {
            const mappingBarang = barang?.data?.data ? barang.data.data.map(val => ({label: val.nama_item, value: val.id_item_buaso})) : []

			setDropdownFilter({
				...dropdownFilter,
				barang: [ ...mappingBarang]
			})
		}).catch(() => {
			setDropdownFilter({
				...dropdownFilter,
				barang: []
			})
		}).finally(() => setLoadingBarang(false))
	}
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        dari: data.dari,
        sampai: data.sampai,
        id_jenis: data.id_jenis,
        id_barang: data.id_barang,
        id_gudang: data.id_gudang,
        id_kelompok: data.id_kelompok
    }
    const formValidationSchema = Yup.object().shape({
        dari: Yup.date().typeError("Masukkan Tanggal").required("Masukkan Tanggal"),
        sampai: Yup.date().required("Masukan Tanggal"),
        id_kelompok: Yup.string().required("Pilih Kelompok").nullable(),
        id_jenis: Yup.string().required("Pilih Jenis").nullable(),
        id_barang: Yup.string().required("Pilih Barang").nullable(),
        id_gudang: Yup.string().required("Pilih Gudang").nullable(),
    })
    const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive
		})
        setShow(false)
		getData()
    }
// HANDLE CHANGE 
    const onTanggalChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            dari: startDate,
            sampai: endDate
        })
    }
    const onResetButtonClick = (values, setValues) => {
		setLoading(true)

		const myPromise = new Promise(resolve => {
			setValues({
				dari: null,
				sampai: null,
				id_kelompok: null,
				id_jenis: null,
				id_barang: null,
				id_gudang: null,
			})
			setTimeout(() => resolve(), 300)
		})
		myPromise.then(() => setLoading(false))
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}>
            
            {({values, errors, touched, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
					<DatePicker 
						selectsRange
						label="Tanggal"
						placeholderText="Pilih Tanggal"
						startDate={values.dari ? new Date(values.dari) : ''}
						endDate={values.sampai ? new Date(values.sampai) : ''}
						onChange={dates => onTanggalChange(dates, values, setValues)}
						monthsShown={2}
						error={!!(errors.dari || errors.sampai) && !!(touched.dari || touched.sampai) && true}
						errorText={!!(errors.dari && touched.dari) && errors.dari}
					/>
                    <SelectSearch 
                        key="1"
                        label="Kelompok"
                        placeholder="Pilih Kelompok"
						defaultValue={dropdownFilter.kelompok.find(val => val.value === values.id_kelompok)}
						option={dropdownFilter.kelompok}
						onChange={val => getDataJenis(val.value, values, setValues)}
						loading={loading}
						error={errors.id_kelompok && touched.id_kelompok && true}
						errorText={errors.id_kelompok && touched.id_kelompok && errors.id_kelompok}
                    />
                    <SelectSearch 
                        key="2"
                        label="Jenis"
                        placeholder="Pilih Jenis"
						defaultValue={dropdownFilter.jenis.find(val => val.value === values.id_jenis)}
						option={dropdownFilter.jenis}
						onChange={val => getDataBarang(val.value, values, setValues)}
						loading={loading || loadingJenis}
						isDisabled={!Boolean(values.id_kelompok)}
						error={errors.id_jenis && touched.id_jenis && true}
						errorText={errors.id_jenis && touched.id_jenis && errors.id_jenis}
                    />
                    <SelectSearch
                        key="3"
                        label="Nama Barang"
                        placeholder="Pilih Nama Barang"
						defaultValue={dropdownFilter.barang.find(val => val.value === values.id_barang)}
						option={dropdownFilter.barang}
						onChange={val => setFieldValue('id_barang', val.value)}
						loading={loading || loadingBarang}
						isDisabled={!Boolean(values.id_jenis)}
						error={errors.id_barang && touched.id_barang && true}
						errorText={errors.id_barang && touched.id_barang && errors.id_barang}
                    />
                    <SelectSearch
                        key="4"
                        label="Gudang"
                        placeholder="Pilih Gudang"
						defaultValue={dropdownFilter.gudang.find(val => val.value === values.id_gudang)}
						option={dropdownFilter.gudang}
						onChange={val => setFieldValue('id_gudang', val.value)}
						loading={loading}
						error={errors.id_gudang && touched.id_gudang && true}
						errorText={errors.id_gudang && touched.id_gudang && errors.id_gudang}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;
