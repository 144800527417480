// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

const ListData = ({ title, text }) => (
	<div className='mb-2'>
		<small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
		<div style={{ fontSize: 14 }}>{text ?? '-'}</div>
	</div>
)

const InfoSection = ({ data }) => {
	const alamat = `${data.alamat_pengiriman}${data?.customer?.nama_desa && ', Desa '+ data?.customer?.nama_desa}${data?.customer?.nama_kecamatan && ', Kec. '+ data?.customer?.nama_kecamatan}${data?.customer?.nama_kabupaten && ', '+ data?.customer?.nama_kabupaten}${data?.customer?.nama_provinsi && ', '+ data?.customer?.nama_provinsi}`

	return (
		<>
			<Row>
				<Col lg={3}>
					<ListData title="Tgl. Sales Order" text={data.tgl_sales_order ? DateConvert(new Date(data.tgl_sales_order)).detail : '-'} />
					<ListData title="No. Sales Order" text={data.no_sales_order ?? '-'} />
					<ListData title="Batas Waktu Pengiriman" text={data.batas_waktu ? DateConvert(new Date(data.batas_waktu)).detail : '-'} />
				</Col>
				<Col lg={3}>
					<ListData title="Sales" text={data.nama_sales ?? '-'} />
					<ListData title="Customer" text={data.nama_customer ?? '-'} />
					<ListData title="Gudang Asal" text={data.nama_gudang ?? '-'} />
				</Col>
				<Col lg>
					<ListData title="Alamat Pengiriman" text={data.alamat_pengiriman ?? '-'} />
					<ListData title="Catatan Sales Order" text={data.catatan ?? '-'} />
				</Col>
			</Row>
			<hr />
		</>
	)
}

const InfoDOSection = ({ data }) => (
	<Row>
		<Col lg="3">
			<ListData title="Tanggal Delivery Order" text={DateConvert(new Date(data.tgl_delivery_order)).detail} />
		</Col>
		<Col lg="3">
			<ListData title="No. Delivery Order" text={data.no_delivery_order} />
		</Col>
	</Row>
)

const InfoCatatanSection = ({ data }) => <ListData title="Catatan Delivery Order" text={data.catatan_delivery_order} />

export { InfoSection, ListData, InfoDOSection, InfoCatatanSection }