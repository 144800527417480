import { useEffect, useState } from 'react'
import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'
import { ComplimentApi } from 'api'
import Axios from 'axios'

export const ModalFilter = ({ show, setShow, data, setData }) => {
  const [dropdownCustomer, setDropdownCustomer] = useState([{ label: "Semua", value: undefined }])
  const [dropdownPetugas, setDropdownPetugas] = useState([{ label: "Semua", value: undefined }])
  const [dropdownItem, setDropdownItem] = useState([{ label: "Semua", value: undefined }])
  const [isLoading, setIsLoading] = useState(false)
  const dropdownStatusApproval = [
    {
      label: 'APPROVED',
      value: "APP"
    },
    {
      label: 'VERIVIED',
      value: "VER"
    },
    {
      label: 'REVISION',
      value: "REV"
    },
    {
      label: 'REJECTED',
      value: "REJ"
    },
    {
      label: 'PENDING',
      value: "PEN"
    },
  ]
  
  const getInitialData = () => {
    setIsLoading(true)

    Axios.all([
        ComplimentApi.getDropdownCustomer(),
        ComplimentApi.getDropdownKaryawan(),
        ComplimentApi.getDropdownBarang()
    ])
    .then(Axios.spread((customer, karyawan, barang) => {
        const mapCustomer = customer.data.data ? customer.data.data.map(item => ({ value: item.id_customer, label: item.nama_customer, ...item })) : []
        const mapKaryawan = karyawan.data.data ? karyawan.data.data.map(item => ({ value: item.id_karyawan, label: item.nama_karyawan, ...item })) : []
        const mapBarang = barang.data.data ? barang.data.data.map(item => ({ value: item.id_item_buaso, label: item.nama_item, ...item })) : []

        setDropdownCustomer([...dropdownCustomer, ...mapCustomer])
        setDropdownPetugas([...dropdownPetugas, ...mapKaryawan])
        setDropdownItem([...dropdownItem, ...mapBarang])
    }))
    .catch((err) => console.log(err))
    .finally(() => setIsLoading(false))
  }
  useEffect(() => {
    getInitialData()
  }, [])
  
  const formInitialValues = {
    tgl_compliment_mulai: data?.filter?.tgl_compliment_mulai,
    tgl_compliment_selesai: data?.filter?.tgl_compliment_selesai,
    customer: data?.filter?.customer,
    petugas: data?.filter?.petugas,
    item_barang: data?.filter?.item_barang,
    status_approval: data?.filter?.status_approval,
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
       })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
         })
    }

    setShow(false)
  }
  const onChangeCompliment = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_compliment_mulai: startDate,
        tgl_compliment_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_compliment_mulai: undefined,
      tgl_compliment_selesai: undefined,
      customer:  undefined,
      petugas:  undefined,
      item_barang:  undefined,
      status_approval:  undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
      
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick(values, setValues)}
        onFilterButtonClick={handleSubmit}>

        <DatePicker
          selectsRange
          label="Tgl. Compliment"
          dateFormat="dd/MM/yyyy"
          placeholderText="Pilih tanggal compliment"
          startDate={values.tgl_compliment_mulai ? new Date(values.tgl_compliment_mulai) : ''}
          endDate={values.tgl_compliment_selesai ? new Date(values.tgl_compliment_selesai) : ''}
          onChange={(dates) => onChangeCompliment(dates, values, setValues)}
          monthsShown={2}
        />
        <SelectSearch
          key={values.customer}
          label="Customer"
          placeholder="Pilih customer"
          loading={isLoading}
          defaultValue={dropdownCustomer.find(item => item.value === values.customer)}
          option={dropdownCustomer}
          onChange={val => setFieldValue('customer', val.value)}
        />
        <SelectSearch
          key={values.petugas}
          label="Petugas Pemberi Compliment"
          placeholder="Pilih petugas"
          loading={isLoading}
          defaultValue={dropdownPetugas.find(item => item.value === values.petugas)}
          option={dropdownPetugas}
          onChange={val => setFieldValue('petugas', val.value)}
        />
        <SelectSearch
          key={values.item_barang}
          label="Item Barang"
          placeholder="Pilih item barang"
          loading={isLoading}
          defaultValue={dropdownItem.find(item => item.value === values.item_barang)}
          option={dropdownItem}
          onChange={val => setFieldValue('item_barang', val.value)}
        />
        <SelectSearch
          key={values.status_approval}
          label="Status Approval"
          placeholder="Pilih Status Approval"
          loading={isLoading}
          defaultValue={dropdownStatusApproval.find(item => item.value === values.status_approval)}
          option={dropdownStatusApproval}
          onChange={val => setFieldValue('status_approval', val.value)}
        />
      </FilterModal>
    )}
  </Formik>
  )
}
