import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { PenerimaanBarangApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataVendor, setDataVendor] = useState([{ label: "Semua", value: undefined }]);
  const [dataItemBarang, setDataItemBarang] = useState([{ label: "Semua", value: undefined }]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      PenerimaanBarangApi.getDropdownVendor(),
      PenerimaanBarangApi.getDropdownItemBarang(),
    ])
      .then(
        Axios.spread((vendor, item) => {
          const mapDataVendor = vendor?.data?.data ? vendor.data.data.map((val) => ({ label: val?.nama_vendor, value: val?.id_vendor })) : [];
          const mapDataItemBarang = item?.data?.data ? item.data.data.map((val) => ({ label: val?.nama_item, value: val?.id_item_buaso })) : [];

          setDataVendor([...dataVendor, ...mapDataVendor]);
          setDataItemBarang([...dataItemBarang, ...mapDataItemBarang]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_purchase_order_mulai: data?.filter?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: data?.filter?.tgl_purchase_order_selesai,
    vendor: data?.filter?.vendor,
    item_barang: data?.filter?.item_barang,
  };
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalPOChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_purchase_order_mulai: startDate,
      tgl_purchase_order_selesai: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_purchase_order_mulai: undefined,
      tgl_purchase_order_selesai: undefined,
      vendor: undefined,
      item_barang: undefined,
    });
  };

  return (
    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Purchase Order"
            placeholderText="Pilih tanggal Purchase Order"
            startDate={
              values.tgl_purchase_order_mulai ? new Date(values.tgl_purchase_order_mulai) : ""
            }
            endDate={
              values.tgl_purchase_order_selesai ? new Date(values.tgl_purchase_order_selesai) : ""
            }
            onChange={(dates) => onTanggalPOChange(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.vendor}
            label="Vendor"
            placeholder="Pilih vendor"
            defaultValue={dataVendor.find((val) => val.value === values.vendor)}
            option={dataVendor}
            onChange={(val) => setFieldValue("vendor", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.item_barang}
            label="Item Barang"
            placeholder="Pilih item barang"
            defaultValue={dataItemBarang.find((val) => val.value === values.item_barang)}
            option={dataItemBarang}
            onChange={(val) => setFieldValue("item_barang", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
