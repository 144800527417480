import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import Axios from 'axios'
import {
    CRUDLayout,
    THead,
    TBody,
    ThFixed,
    Tr,
    Th,
    Td,
    TdFixed,
    SelectSearch,
    ActionButton,
    DataStatus
} from 'components'
import { RupiahConvert } from 'utilities'
import { ComplimentApi } from 'api'
  
const ModalTambahBarang = ({ modalBarangConfig, setModalBarangConfig, listCompliment, setListCompliment, valueItem, setValueItem }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [loadingJenis, setLoadingJenis] = useState(false)
    const [loadingBarang, setLoadingBarang] = useState(false)

    const [dataStokBarang, setDataStokBarang] = useState([])
    const [dropdown, setDropdown] = useState({
        kelompok: [],
        jenis: [],
        barang: []
    })
    const [form, setForm] = useState({
        id_kelompok: '',
        id_jenis: '',
        id_barang: '',
        id_compliment_detail: null
    })

    const handleQtyCompliment = (index, qty_item) => {
        const newData = [...dataStokBarang]
        newData[index].qty_item = qty_item
        newData[index].sub_total = parseInt(qty_item) * parseInt(newData[index].harga_terbaru ?? 0)
        setDataStokBarang(newData)
    }
    const getInitialData = () => {
        setIsLoading(true)

        Axios.all([
            ComplimentApi.getDropdownKelompok(),
            // ComplimentApi.getDropdownJenis()
        ])
        .then(Axios.spread((resKelompok, resJenis) => {
            const kelompok = resKelompok.data.data ? resKelompok.data.data.map(item => ({ value: item.id_kelompok, label: item.nama_kelompok, ...item })) : []
            // const jenis = resJenis.data.data ? resJenis.data.data.map(item => ({ value: item.id_jenis, label: item.nama_jenis, ...item })) : []

            setDropdown({ kelompok })
        }))
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    }
    const getDropdownJenis = async () => {
        setLoadingJenis(true)
        await ComplimentApi.getDropdownJenis()
        .then((resJenis) => {
            const jenis = resJenis.data.data ? resJenis.data.data.map(item => ({ value: item.id_jenis, label: item.nama_jenis, ...item })) : []
            setDropdown((prev) => ({ ...prev, jenis }))
            setLoadingJenis(false)
        })
    }
    const getDropdownBarang = async (id_jenis) => {
        setLoadingBarang(true)
        await ComplimentApi.getDropdownBarang({ id_jenis })
        .then((res) => {
            const barang = res.data.data.map(item => ({ value: item.id_item_buaso, label: `${item.kode_item} | ${item.nama_item}`, ...item }))
            setDropdown((prev) => ({ ...prev, barang }))
            setLoadingBarang(false)
        })
    }
    const getTableStokBarang = async (id_barang) => {
        setLoadingTable(true)
        await ComplimentApi.getStokBarang({ id_barang })
        .then((res) => {
            setDataStokBarang(res?.data?.data)
            setLoadingTable(false)
        })
    }

    const handleSubmit = () => {
        const selected = dataStokBarang.filter(val => val.qty_belanja > 0)
        const currentData = [...listCompliment]
    
        const checkExist = (item) => currentData.findIndex(val => (val.id_barang === item.id_barang) && (val.id_gudang === item.id_gudang))
    
        selected.forEach(item => {
          // get jumlah stok barang di gudang
          const qtyStok = dataStokBarang.find(val => (val.id_barang === item.id_barang) && (val.id_gudang === item.id_gudang)).stok
          const getIndex = checkExist(item)
    
          if (getIndex > -1) {
            const floatPrevQtyBelanja = parseFloat(currentData[getIndex].qty_belanja)         // parse float qty_belanja pada item sebelumnya
            const floatCurrQtyBelanja = parseFloat(item.qty_belanja)                          // parse float qty_belanja pada item sekarang
            const accumulateQty = floatPrevQtyBelanja + floatCurrQtyBelanja                   // akumulasi qty_belanja
    
            currentData[getIndex].qty_belanja = (accumulateQty <= parseFloat(qtyStok)) ? accumulateQty : parseFloat(qtyStok)
          } else {
            currentData.unshift(item)
          }
        })
    
        setListCompliment(currentData)
        setModalBarangConfig(false)
        setValueItem(form)
      }

    useEffect(() => {
        getInitialData()
        return () => setDropdown({})
    }, [])

    return (
        <Modal
            size='lg'
            scrollable={true}
            show={modalBarangConfig}
            onHide={() => setModalBarangConfig(false)}>

            <Modal.Header closeButton>
                <h6 className="mb-0 text-primary">Tambah Data Item Barang</h6>
            </Modal.Header>
            {!isLoading 
                ?   <Modal.Body style={{ minHeight: 400 }}>
                        <Row>
                            <Col>
                                <SelectSearch
                                    label='Kelompok Barang'
                                    placeholder='Pilih kelompok barang'
                                    defaultValue={form?.id_kelompok}
                                    options={dropdown?.kelompok}
                                    onChange={async (e) => {
                                        await getDropdownJenis()
                                        setForm(() => ({ id_kelompok: e.value, id_jenis: '', id_barang: '' }))
                                    }}
                                />
                            </Col>
                            <Col>
                                <SelectSearch
                                    key={form?.id_kelompok}
                                    isDisabled={!form?.id_kelompok}
                                    label='Jenis Barang'
                                    placeholder='Pilih jenis barang'
                                    loading={loadingJenis}
                                    option={dropdown?.jenis ? dropdown?.jenis.filter(item => item.id_kelompok === form?.id_kelompok) : dropdown?.jenis}
                                    onChange={async (e) => {
                                        await getDropdownBarang(e.value)
                                        setForm((prev) => ({ ...prev, id_jenis: e.value, id_barang: '' }))
                                    }}
                                />
                            </Col>
                        </Row>
                        <SelectSearch
                            key={form.id_jenis}
                            isDisabled={!form.id_jenis}
                            label='Item Barang'
                            placeholder='Pilih item barang'
                            loading={loadingBarang}
                            option={dropdown?.barang ?? []}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={async (e) => {
                                await getTableStokBarang(e.value)
                                setForm((prev) => ({ ...prev, id_barang: e.value, id_compliment_detail: null }))
                            }}
                        />
                        <div className='mt-4'>
                            {loadingTable 
                                ? <DataStatus loading={loadingTable} text='Memuat Data . . .' />
                                : <CRUDLayout.Table>
                                    <THead>
                                        <tr>
                                            <ThFixed>No</ThFixed>
                                            <Th>Gudang</Th>
                                            <Th width={100}>Satuan</Th>
                                            <Th width={80}>Qty. Stok</Th>
                                            <Th width={80}>Qty. Compliment</Th>
                                            <Th width={130}>Harga Satuan</Th>
                                            <Th width={130}>Sub Total</Th>
                                        </tr>
                                    </THead>
                                    <TBody>
                                        {dataStokBarang?.map((val, index) => {
                                            const sub_total = Math.round(parseFloat(val?.qty_belanja || 0) * parseFloat(val.harga_terbaru || 0))
                                            return (
                                                <Tr key={val.id_gudang}>
                                                    <TdFixed>{index + 1}</TdFixed>
                                                    <Td>{val.nama_gudang ?? "-"}</Td>
                                                    <Td>{val.nama_satuan ?? "-"}</Td>
                                                    <Td><div className='text-right'>{val.stok ? parseFloat(val.stok) : 0}</div></Td>
                                                    <Td>
                                                        <input
                                                        width={100}
                                                        type="number"
                                                        step={0.01}
                                                        min={0}
                                                        value={parseFloat(val.qty_belanja)}
                                                        className="text-right form-control"
                                                        onChange={(e) => {
                                                            const items = [...dataStokBarang]
                                                            const item = { ...items[index] }
                                                            const value = Number.isInteger(e.target.value || 0) ? e.target.value : parseFloat(e.target.value || 0).toFixed(2)
                                                            const qty = parseFloat(value || 0) <= parseFloat(item.stok || 0) ? value : item.stok
                                                            item.qty_belanja = qty
                                                            items[index] = item
                                                            setDataStokBarang(items)
                                                        }}
                                                        />
                                                    </Td>
                                                    <Td><div className='text-right'>{val.harga_terbaru ? RupiahConvert(String(val.harga_terbaru)).getWithComa : "Rp.0"}</div></Td>
                                                    <Td className="text-right">{sub_total ? RupiahConvert(String(sub_total || 0)).getWithComa : "Rp.0"}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </TBody>
                                  </CRUDLayout.Table>
                            }
                        </div>
                    </Modal.Body>
                :   <DataStatus loading={isLoading} text='Memuat data...' />
            }
            {!isLoading && (
                <Modal.Footer>
                    <ActionButton
                        text="Batal"
                        className="pl-4 pr-4"
                        variant="light"
                        onClick={() => setModalBarangConfig(false)}
                    />
                    {/* <ActionButton
                        text="Tambah"
                        className="pl-4 pr-4"
                        onClick={() => {
                            // filter stok barang yang dipilih user dengan qty_item > 0
                            const selectedStokBarang = dataStokBarang.filter((e) => e.hasOwnProperty("qty_item") && parseInt(e.qty_item) > 0);
                            // const selectedStokBarang = dataStokBarang
                            let listCompliment = [...listCompliment]
                            // fungsi hapus barang pada listCompliment, jika barang sudah ada di selectedStokBarang 
                            const spliceBarang = (obj) => {
                                listCompliment.forEach((item, index) => {
                                    if (item.id_barang === obj.id_barang && item.id_gudang === obj.id_gudang) {
                                        listCompliment.splice(index, 1)
                                    }
                                })
                                setListCompliment([...listCompliment]);
                            };

                            selectedStokBarang.forEach((obj) => spliceBarang(obj))
                            setListCompliment([...selectedStokBarang, ...listCompliment,])
                            setModalBarangConfig(false)
                        }} 
                    /> */}
                    <ActionButton
                        text="Tambah"
                        onClick={() => handleSubmit()}
                    />
                </Modal.Footer>
            )}
        </Modal>
    )
}
  
export default ModalTambahBarang