// React
import { useState, useEffect } from 'react'

// Component
import { Row, Col } from 'react-bootstrap'
import { DatePicker, Input, SelectSearch, TextArea } from 'components'
import { DateConvert } from 'utilities'
import { Checkbox as ViewCheckbox } from "../components";

// API
import { SuratJalanApi } from 'api'
import {
  Spinner
} from 'react-bootstrap'

// Page Component
import { TableSection as CreateTableSection } from '../TambahSuratJalan/Section'
import { TableSection as UpdateTableSection } from '../UbahSuratJalan/Section'

const FormSection = ({
  formik, fetchingStatus, formRendered,
  setFormRendered, dataPetugas, dataArmada,
  dataTable, setDataTable, type, dataGudang,
  getInitialData, 
  // startDate, setStartDate
}) => {
  const today = DateConvert(new Date()).default
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const [isSelectedLoading, setIsSelectedLoading] = useState(false)
  const {values, errors, touched, setValues, setFieldValue, handleChange} = formik

  const getNomorHandler = date => {
    setIsNomorLoading(true)
    
    SuratJalanApi.getNomor({tanggal: date})
      .then(no => {
        const nomor = no.data.data
        setValues({
          ...values,
          nomor: nomor,
          tanggal: date
        })
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: "",
          tanggal: ""
        })
        window.alert("Nomor gagal dimuat")
      })
      .finally(() => {setIsNomorLoading(false)})
  }

  useEffect(() => {
    // !fetchingStatus.loading && formRendered.toString() === '0' && getNomorHandler(today)

    return () => {
      setFormRendered(formRendered + 1)
      setIsNomorLoading(false)
    }
  }, [])

  const DataStatus = ({text, loading}) => {
    return (
      <div>
        {loading && (<Spinner animation="border" size="sm" className="mr-2 mt-1" />)}
        <small>{text}</small>
      </div>
    )
  }
  
  return (
    <div>
      <Row>
        <Col lg={6}>
          <DatePicker 
            label="Tgl. Surat Jalan"
            placeholderText="Pilih tanggal"
            dateFormat="dd/MM/yyyy"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            // selected={type !== 'update' ? startDate : values.tanggal ? new Date(values.tanggal) : ""}
            onChange={date => {
              const newDate = DateConvert(date).default
              getNomorHandler(newDate)
              type !== 'update' && getInitialData(newDate)
              // setStartDate(date)
            }}
            error={Boolean(errors.tanggal) && touched.tanggal}
            errorText={Boolean(errors.tanggal) && touched.tanggal && errors.tanggal}
          />
        </Col>
        <Col lg={6}>
          <Input 
            readOnly
            // name="nomor"
            label="No. Surat Jalan"
            placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
            error={Boolean(errors.nomor) && touched.nomor}
            errorText={Boolean(errors.nomor) && touched.nomor && errors.petugas_pengiriman}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <SelectSearch 
            label="Petugas Pengiriman"
            placeholder="Pilih petugas pengiriman"
            option={dataPetugas}
            defaultValue={dataPetugas.find(val => val.value === values.petugas_pengiriman)}
            onChange={val => setFieldValue('petugas_pengiriman', val.value)}
            error={Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman}
            errorText={Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman && errors.petugas_pengiriman}
          />
        </Col>
        <Col lg={6}>
          <SelectSearch 
            label="Armada Pengiriman"
            placeholder="Pilih armada pengiriman"
            option={dataArmada}
            defaultValue={dataArmada.find(val => val.value === values.armada_pengiriman)}
            onChange={val => setFieldValue('armada_pengiriman', val.value)}
            error={Boolean(errors.armada_pengiriman) && touched.armada_pengiriman}
            errorText={Boolean(errors.armada_pengiriman) && touched.armada_pengiriman && errors.armada_pengiriman}
          />
        </Col>
      </Row>
      <hr/>
      <div>
        <ViewCheckbox 
          label="Konsinyasi" 
          id="is_konsinyasi"
          name="is_konsinyasi"
          defaultValue={values.is_konsinyasi}
          onChange={(e) => {
            setFieldValue('is_konsinyasi', e.target.checked)
            setIsSelectedLoading(true);
            setTimeout(() => setIsSelectedLoading(false), 500);
          }}
        />
      </div>
        {isSelectedLoading === true 
          ? <DataStatus loading={true} text="Memuat Data ..." />
          : values.is_konsinyasi === true 
            ? <Row>
                <Col lg={6}>
                  <SelectSearch 
                    label="Gudang Tujuan"
                    placeholder="Pilih gudang tujuan"
                    option={dataGudang}
                    defaultValue={dataGudang.find(val => val.value === values.id_gudang_tujuan_konsinyasi)}
                    onChange={val => setFieldValue('id_gudang_tujuan_konsinyasi', val.value)}
                    // error={Boolean(errors.id_gudang_tujuan_konsinyasi) && touched.id_gudang_tujuan_konsinyasi}
                    // errorText={Boolean(errors.id_gudang_tujuan_konsinyasi) && touched.id_gudang_tujuan_konsinyasi && errors.id_gudang_tujuan_konsinyasi}
                  />
                </Col>
              </Row>
            : ""
      }
      <hr />

      {type === 'create' ? (
        <CreateTableSection dataTable={dataTable} setDataTable={setDataTable} />
      ) : (
        <UpdateTableSection dataTable={dataTable} setDataTable={setDataTable} />
      )}

      <TextArea
        label="Catatan Surat Jalan"
        name="catatan_surat_jalan"
        value={values.catatan_surat_jalan}
        onChange={handleChange}
        rows={4}
        error={Boolean(errors.catatan_surat_jalan) && touched.catatan_surat_jalan}
        errorText={Boolean(errors.catatan_surat_jalan) && touched.catatan_surat_jalan && errors.catatan_surat_jalan}
      />
    </div>
  )
}

export default FormSection