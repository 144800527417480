import Services from "../../../services";

class GudangApi {
  getPage(page, dataLength, key) {
    return Services.get(`/gudang/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  page(params) {
    return Services.get('/gudang/page', { params });
  }
  getKaryawan() {
    return Services.get("/karyawan/dropdown");
  }
  getUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }
  getUnitProduksi() {
    return Services.get("/gudang/unit_produksi");
  }
  generateKodeItem() {
    return Services.get("/gudang/no_baru");
  }
  create(data) {
    return Services.post("/gudang", data);
  }
  update(data) {
    return Services.put("/gudang", data);
  }
  delete(id_gudang) {
    return Services.post("/gudang/delete", id_gudang);
  }
  search(key) {
    return Services.get(`gudang/page/?q=${key}`);
  }
  show(id_gudang) {
    return Services.put("/gudang/show", id_gudang);
  }
  hide(id_gudang) {
    return Services.put("/gudang/hide", id_gudang);
  }
  checkDuplicateNama(params) {
    return Services.get('/gudang/check_duplicate/', { params })
  }
}

export default new GudangApi();
