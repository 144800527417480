import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'

export const ModalFilterPR = ({ show, setShow, data, setData }) => {
  const [dropdownStatusApproval, setDropdownStatusApproval] = useState([
    {
      label: 'APPROVED',
      value: "APP"
    },
    {
      label: 'VERIVIED',
      value: "VER"
    },
    {
      label: 'REVISION',
      value: "REV"
    },
    {
      label: 'REJECTED',
      value: "REJ"
    },
    {
      label: 'PENDING',
      value: "PEN"
    },
  ])
  const [dropdownKeperluan, setDropdownKeperluan] = useState([
    {label: 'Semua', value: undefined},
    {label: 'Stok', value: 'stok'},
    {label: 'Produksi', value: 'produksi'}
  ])

  const formInitialValues = {
    tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
    tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
    tgl_pemakaian_mulai: data?.filter?.tgl_pemakaian_mulai,
    tgl_pemakaian_selesai: data?.filter?.tgl_pemakaian_selesai,
    keperluan: data?.filter?.keperluan,
    status_approval: data?.filter?.status_approval,
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
       })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
         })
    }

    setShow(false)
  }
  const onChangePR = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_request_mulai: startDate,
        tgl_purchase_request_selesai: endDate,
    })
  }
  const onChangePemakaian = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_pemakaian_mulai: startDate,
        tgl_pemakaian_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_purchase_request_mulai: undefined,
      tgl_purchase_request_selesai: undefined,
      tgl_pemakaian_mulai:  undefined,
      tgl_pemakaian_selesai:  undefined,
      keperluan:  undefined,
      status_approval:  undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
      
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick(values, setValues)}
        onFilterButtonClick={handleSubmit}>

        <DatePicker
          selectsRange
          label="Tgl. Purchase Request"
          dateFormat="dd/MM/yyyy"
          placeholderText="Pilih tanggal purchase request"
          startDate={values.tgl_purchase_request_mulai ? new Date(values.tgl_purchase_request_mulai) : ''}
          endDate={values.tgl_purchase_request_selesai ? new Date(values.tgl_purchase_request_selesai) : ''}
          onChange={(dates) => onChangePR(dates, values, setValues)}
          monthsShown={2}
        />
        <DatePicker
          selectsRange
          label="Tgl. Pemakaian"
          dateFormat="dd/MM/yyyy"
          placeholderText="Pilih tanggal pemakaian"
          startDate={values.tgl_pemakaian_mulai ? new Date(values.tgl_pemakaian_mulai) : ''}
          endDate={values.tgl_pemakaian_selesai ? new Date(values.tgl_pemakaian_selesai) : ''}
          onChange={(dates) => onChangePemakaian(dates, values, setValues)}
          monthsShown={2}
        />
        <SelectSearch
          key={values.keperluan}
          label="Keperluan"
          placeholder="Pilih keperluan"
          defaultValue={dropdownKeperluan.find(item => item.value === values.keperluan)}
          option={dropdownKeperluan}
          onChange={val => setFieldValue('keperluan', val.value)}
        />
        <SelectSearch
          key={values.status_approval}
          label="Status Approval"
          placeholder="Pilih Status Approval"
          defaultValue={dropdownStatusApproval.find(item => item.value === values.status_approval)}
          option={dropdownStatusApproval}
          onChange={val => setFieldValue('status_approval', val.value)}
        />
      </FilterModal>
    )}
  </Formik>
  )
}
