// Component
import {
  Table, Th, Td, DataStatus, TdFixed,
  ThFixed, InputQtyDecimal
} from 'components'

const TableSection = ({ dataTable, setDataTable, type }) => {
  const decimalConvert = value => {
    const newValue = value.toString().replace(/[^0-9\.]/g,"")
    const convert = newValue.substring(0, 5)
    
    return convert
  }
  const onQtySJChange = (value, index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, qty_surat_jalan: parseFloat(value) > parseFloat(val.qty_sisa_do) ? 0 : value} : { ...val }
    })
    setDataTable(newData)
  }
  const onQtyPakaiChange = (value, index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, qty_satuan_pakai: value } : { ...val };
    });

    setDataTable(newData);
  };
  return (
    <>
      <div className="p-1">
        <b style={{ fontSize: "14px" }}>List Item Delivery Order</b>
      </div>
      <Table>
        <thead className={type !== "detail" ? "bg-light" : ""}>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th style={{ minWidth: "400px" }}>Item Barang</Th>
            {type !== "detail" && <Th style={{ minWidth: "100px" }}>Stok Gudang</Th>}
            <Th style={{ minWidth: "100px" }}>Qty. DO</Th>
            {type !== "detail" && <Th style={{ minWidth: "100px" }}>Qty. Menunggu Persetujuan</Th>}
            {type !== "detail" && <Th style={{ minWidth: "100px" }}>Qty. Terkirim</Th>}
            {type !== "detail" && <Th style={{ minWidth: "100px" }}>Qty. Sisa DO</Th>}
            <Th style={{ minWidth: "100px" }}>Qty. Surat Jalan</Th>
            <Th style={{ minWidth: "100px" }}>Satuan Jual</Th>
            <Th style={{ minWidth: "100px" }}>Qty. Satuan Pakai</Th>
            <Th style={{ minWidth: "100px" }}>Satuan Pakai</Th>
          </tr>
        </thead>
        <tbody>
          {dataTable.length > 0
            ? dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                <Td>{val.nama_item ?? "-"}</Td>
                {type !== "detail" && <Td className="text-right">{val.stok_gudang ? parseFloat(val.stok_gudang) : 0}</Td>}
                <Td className="text-right">{val.qty_do ? parseFloat(val.qty_do) : 0}</Td>
                {type !== "detail" && <Td className="text-right">{val.qty_menunggu_persetujuan ? parseFloat(val.qty_menunggu_persetujuan) : 0}</Td>}
                {type !== "detail" && <Td className="text-right">{val.qty_terkirim ? parseFloat(val.qty_terkirim) : 0}</Td>}
                {type !== "detail" && <Td className="text-right">{val.qty_sisa_do ? parseFloat(val.qty_sisa_do) : 0}</Td>}
                {type === "detail"
                  ? <Td className="text-right">{val.qty_surat_jalan ? parseFloat(val.qty_surat_jalan) : 0}</Td>
                  : <Td className="text-right" style={{width: 125}}>
                      {/* <input 
                        className="form-control form-control-sm"
                        placeholder="Masukan qty."
                        defaultValue={val.qty_surat_jalan}
                        onChange={e => {
                          e.target.value = decimalConvert(e.target.value)
                        }}
                        disabled={true}
                        onBlur={e => {
                          const value = decimalConvert(e.target.value)
                          onQtySJChange(value, index)
                        }}
                      /> */}
                      <InputQtyDecimal
                        placeholder="Masukan qty."
                        value={val.qty_surat_jalan}
                        disabled={val.stok_gudang === 0 ? true : val.qty_stok_gudang < val.qty_sisa_do ? true : false }
                        onChange={(e) => { onQtySJChange(e, index) }}
                      />
                    </Td>
                }
                <Td>{val.satuan ?? "-"}</Td>
                {type === "detail"
                  ? <Td className="text-right">{val.qty_satuan_pakai ? parseFloat(val.qty_satuan_pakai) : 0}</Td>
                  : <Td style={{ width: 125 }}>
                      {/* <input 
                        className="form-control form-control-sm"
                        placeholder="Masukan qty."
                        defaultValue={val.qty_satuan_pakai ?? 0}
                        onChange={e => {
                          e.target.value = decimalConvert(e.target.value)
                        }}
                        disabled={true}
                        onBlur={e => {
                          const value = decimalConvert(e.target.value)
                          onQtySJChange(value, index)
                        }}
                      /> */}
                      <InputQtyDecimal
                        placeholder="Masukan qty."
                        value={val.qty_satuan_pakai}
                        disabled={val.stok_gudang === 0 ? true : val.qty_stok_gudang < val.qty_sisa_do ? true : false }
                        onChange={(e) => { onQtyPakaiChange(e, index) }}
                      />
                    </Td>
                }
                <Td>{val.satuan_pakai ?? "Botol"}</Td>
              </tr>
            ))
            : <tr>
                <td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
          }
        </tbody>
      </Table>
    </>
  )
}

export default TableSection