import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Card, Row, Col } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import Axios from "axios"
import {
  CRUDLayout,
  Input,
  ActionButton,
  DataStatus,
  DeleteButton,
  Alert,
  SelectSearch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  TextArea,
  Table
} from "components"
import { ModalTambahBarang } from "./section"
import { RupiahConvert } from "utilities"
import { ComplimentApi } from "api"

const ComplimentCreate = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().split("T")[0]
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [modalBarangConfig, setModalBarangConfig] = useState(false)
  const [valueItem, setValueItem] = useState([])
  const [listCompliment, setListCompliment] = useState([])
  console.log("list", listCompliment)
  console.log("listValue", valueItem)

  const [dropdownCustomer, setDropdownCustomer] = useState([])
  const [dropdownKaryawan, setDropdownKaryawan] = useState([])
  const [noCompliment, setNoCompliment] = useState("")
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    show: false,
    text: "",
  })

  const getNomorCompliment = async (date = TODAY.replace(/-/g, '/')) => {
    return await ComplimentApi.getNomor({ tanggal: date })
      .then((no_compliment) => no_compliment?.data?.data ?? '')
      .catch((err) => alert(err.response.data.data))
  }
  const getData = async () => {
    setIsLoading(true)

    const noCompliment = await getNomorCompliment()
    setNoCompliment(noCompliment)

    Axios.all([
      ComplimentApi.getDropdownCustomer(),
      ComplimentApi.getDropdownKaryawan()
    ])
      .then(
        Axios.spread((resCustomer, resKaryawan) => {
          const customer = resCustomer?.data?.data ? resCustomer?.data?.data?.map((val) => Object({ value: val.id_customer, label: val.nama_customer })) : []
          const karyawan = resKaryawan?.data?.data ? resKaryawan?.data?.data?.map((val) => Object({ value: val.id_karyawan, label: val.nama_karyawan })) : []

          setDropdownCustomer(customer)
          setDropdownKaryawan(karyawan)
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setNavbarTitle("Compliment")
    getData()
    getNomorCompliment()
  }, [])

  const formInitialValues = {
    tgl_compliment: TODAY,
    no_compliment: noCompliment,
    keterangan: "",
    id_customer: "",
    id_petugas: "",
  }
  const formValidationSchema = Yup.object().shape({
    tgl_compliment: Yup.string().required("Tanggal Compliment Harus Diisi"),
    no_compliment: Yup.string().required("Pilih No Compliment"),
    id_customer: Yup.string().required("Pilih Customer"),
    id_petugas: Yup.string().required("Pilih Petugas"),
  })
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (listCompliment.length > 0) {
      let finalListCompliment = []

      listCompliment.map((obj) =>
        finalListCompliment.push({
          qty_compliment: obj.qty_belanja,
          harga_satuan: parseInt(obj.harga_terbaru ?? 0),
          id_gudang: obj.id_gudang,
          id_item_buaso: obj.id_barang,
        })
      )

      const finalValues = {
        ...values,
        detail: finalListCompliment,
      }

      ComplimentApi.create(finalValues)
        .then(() => history.push("/inventory/transaksi/compliment", {
          registerAlertConfig: {
            variant: "primary",
            show: true,
            text: "Tambah data berhasil!"
          }
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          show: true,
          text: `Tambah data gagal! (${err.response.data.message})`
        }))
        .finally(() => setSubmitting(false))

    } else {
      setSubmitting(false)
      setAlertConfig({
        variant: "danger",
        show: true,
        text: 'List Barang tidak boleh kosong!',
      })
    }
  }
  const total = listCompliment?.reduce((acc, val) => Math.round(acc + parseFloat(val?.qty_belanja || 0) * parseFloat(val?.harga_terbaru || 0)), 0)

  const TableItemCompliment = () => (
    <>
      <div className="mb-1" style={{ fontSize: "14px" }}><b>Item Compliment</b></div>
      <Table>
        <THead>
          <tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th>Item Barang</Th>
            <Th style={{ width: 200 }}>Gudang</Th>
            <Th style={{ width: 100 }}>Satuan</Th>
            <Th style={{ width: 100 }}>Qty. Compliment</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            <Th style={{ width: 150 }}>Sub Total</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </THead>
        <TBody>
          {listCompliment?.map((item, index) => {
            const sub_total = Math.round(parseFloat(item?.qty_belanja || 0) * parseFloat(item.harga_terbaru || 0))
            return (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{item.kode_barang ?? "-"}</TdFixed>
                <Td>{item.nama_barang ?? "-"}</Td>
                <Td>{item.nama_gudang ?? "-"}</Td>
                <Td>{item.nama_satuan ?? "-"}</Td>
                <Td><div className="text-right">{item.qty_belanja ? parseFloat(item.qty_belanja) : 0}</div></Td>
                <Td><div className="text-right">{item.harga_terbaru ? RupiahConvert(parseInt(item.harga_terbaru).toString()).getWithComa : "Rp. 0"}</div></Td>
                <Td className="text-right">{sub_total ? RupiahConvert(String(sub_total || 0)).getWithComa : "Rp.0"}</Td>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <DeleteButton
                      onClick={() => {
                        let filteredBarang = listCompliment.filter((e, e_index) => e_index !== index)
                        setListCompliment(filteredBarang)
                      }}
                    />
                  </div>
                </TdFixed>
              </Tr>
            )
          })}
          <Tr>
            <Td colSpan={7} className="text-right align-middle py-2 font-weight-bold">Total</Td>
            <Td className="text-right align-middle py-2 font-weight-bold">{total ? RupiahConvert(String(parseInt(total))).getWithComa : "Rp. 0"}</Td>
            <Td />
          </Tr>
        </TBody>
      </Table>
    </>
  )

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/inventory/transaksi/compliment", { ...location?.state })}/>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div className="font-weight-bold mb-2">Tambah Data Compliment</div>

      <CRUDLayout>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        <div>
          {!isLoading ?
              <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}>

                {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue, setSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Card className="pb-3 mt-3">
                        <Card.Body>
                          <Row>
                            <Col lg="6">
                              <Input
                                label="Tgl. Compliment"
                                type="date"
                                name="tgl_compliment"
                                value={values.tgl_compliment}
                                onChange={async (e) => {
                                  const value = e.target.value
                                  const date = value.replace(/-/g, '/')
                                  const no_sales = await getNomorCompliment(date)

                                  setFieldValue("tgl_compliment", value)
                                  setFieldValue("no_compliment", no_sales)
                                }}
                                error={errors.tgl_compliment && touched.tgl_compliment && true}
                                errorText={errors.tgl_compliment}
                              />
                            </Col>
                            <Col lg="6">
                              <Input
                                label="No. Compliment"
                                type="text"
                                name="no_compliment"
                                placeholder="No. Compliment"
                                readOnly={true}
                                value={values.no_compliment}
                                error={errors.no_compliment && touched.no_compliment && true}
                                errorText={errors.no_compliment}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <SelectSearch
                                label="Customer"
                                name="id_customer"
                                placeholder="Pilih Customer"
                                option={dropdownCustomer}
                                onChange={(val) => {setFieldValue("id_customer", val.value)}}
                                defaultValue={dropdownCustomer.find(item => item.value === values.id_customer)}
                                error={errors.id_customer && touched.id_customer && true}
                                errorText={errors.id_customer && touched.id_customer && errors.id_customer}
                              />
                            </Col>
                            <Col lg="6">
                              <SelectSearch
                                label="Petugas Pemberi Compliment*"
                                name="id_petugas"
                                placeholder="Pilih petugas"
                                onChange={(val) => setFieldValue("id_petugas", val.value)}
                                option={dropdownKaryawan}
                                defaultValue={dropdownKaryawan.find(item => item.value === values.id_petugas)}
                                error={errors.id_petugas && touched.id_petugas && true}
                                errorText={errors.id_petugas && touched.id_petugas && errors.id_petugas}
                              />
                            </Col>
                          </Row>
                          <TextArea
                            label="Catatan"
                            placeholder="Masukkan catatan"
                            name="keterangan"
                            value={values.keterangan}
                            onChange={handleChange}
                            error={errors.keterangan && touched.keterangan && true}
                            errorText={errors.keterangan}
                            rows={4}
                          />
                        </Card.Body>
                      </Card>

                      <div className="d-flex justify-content-end mt-4">
                        <ActionButton text='Tambah Barang' onClick={() => setModalBarangConfig(true)} />
                      </div>

                      <TableItemCompliment />
                      
                      <div className="d-flex justify-content-end">
                        <ActionButton
                          type="submit"
                          variant="primary"
                          text="Simpan"
                          className="mt-2 px-4"
                          loading={isSubmitting}
                        />
                      </div>
                    </form>
                  )
                }}
              </Formik>
            : <DataStatus loading={isLoading} text='Memuat Data . . .' />
          }
        </div>
      </CRUDLayout>

      {modalBarangConfig && (
        <ModalTambahBarang
          listCompliment={listCompliment}
          setListCompliment={setListCompliment}
          modalBarangConfig={modalBarangConfig}
          setModalBarangConfig={setModalBarangConfig}
          valueItem={valueItem}
          setValueItem={setValueItem}
        />
      )}
    </CRUDLayout>
  )
}

export default ComplimentCreate
