import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'

export const ModalFilterPermintaanProduksi = ({ dropdown, show, setShow, data, setData }) => {
  const formInitialValues = {
    tgl_permintaan_produksi_awal: data?.filter?.tgl_permintaan_produksi_awal,
    tgl_permintaan_produksi_akhir: data?.filter?.tgl_permintaan_produksi_akhir,
    tgl_job_order_awal: data?.filter?.tgl_job_order_awal,
    tgl_job_order_akhir: data?.filter?.tgl_job_order_akhir,
    id_item_buaso: data?.filter?.id_item_buaso,
    id_karyawan: data?.filter?.id_karyawan,
    // id_depo: data?.filter?.id_depo,
    keperluan_produksi: data?.filter?.keperluan_produksi
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
       })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
         })
    }

    setShow(false)
  }
  const onChangeTglPermintaan = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_permintaan_produksi_awal: startDate,
        tgl_permintaan_produksi_akhir: endDate,
    })
  }
  const onChangeTglJO = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_job_order_awal: startDate,
        tgl_job_order_akhir: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        tgl_permintaan_produksi_awal: undefined,
        tgl_permintaan_produksi_akhir: undefined,
        tgl_job_order_awal: undefined,
        tgl_job_order_akhir: undefined,
        id_item_buaso: undefined,
        id_karyawan: undefined,
        // id_depo: undefined,
        keperluan_produksi: undefined
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
      
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick(values, setValues)}
        onFilterButtonClick={handleSubmit}>

        <DatePicker
          selectsRange
          label="Tgl. Permintaan Produksi"
          placeholderText="Pilih tanggal permintaan produksi"
          startDate={values.tgl_permintaan_produksi_awal ? new Date(values.tgl_permintaan_produksi_awal) : ''}
          endDate={values.tgl_permintaan_produksi_akhir ? new Date(values.tgl_permintaan_produksi_akhir) : ''}
          onChange={(dates) => onChangeTglPermintaan(dates, values, setValues)}
          monthsShown={2}
        />
        <DatePicker
          selectsRange
          label="Tgl. Job Order"
          placeholderText="Pilih tanggal job order"
          startDate={values.tgl_job_order_awal ? new Date(values.tgl_job_order_awal) : ''}
          endDate={values.tgl_job_order_akhir ? new Date(values.tgl_job_order_akhir) : ''}
          onChange={(dates) => onChangeTglJO(dates, values, setValues)}
          monthsShown={2}
        />
        <SelectSearch
          key={values.id_item_buaso}
          label="Item Produksi"
          placeholder="Pilih item produksi"
          defaultValue={dropdown?.item_produksi?.find(item => item.value === values.id_item_buaso)}
          option={dropdown.item_produksi}
          onChange={val => setFieldValue('id_item_buaso', val.value)}
        />
        <SelectSearch
          key={values.id_karyawan}
          label="Petugas Produksi"
          placeholder="Pilih petugas produksi"
          defaultValue={dropdown?.petugas?.find(item => item.value === values.id_karyawan)}
          option={dropdown.petugas}
          onChange={val => setFieldValue('id_karyawan', val.value)}
        />
        {/* <SelectSearch
          key={values.id_depo}
          label="Depo Produksi"
          placeholder="Pilih depo produksi"
          defaultValue={dropdown?.depo?.find(item => item.value === values.id_depo)}
          option={dropdown.depo}
          onChange={val => setFieldValue('id_depo', val.value)}
        /> */}
        <SelectSearch
          key={values.keperluan_produksi}
          label="Keperluan Produksi"
          placeholder="Pilih keperluan produksi"
          defaultValue={dropdown?.keperluan?.find(item => item.value === values.keperluan_produksi)}
          option={dropdown.keperluan}
          onChange={val => setFieldValue('keperluan_produksi', val.value)}
        />
      </FilterModal>
      )}
    </Formik>
  )
}
