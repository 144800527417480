import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import Axios from "axios"
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ReadButton,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  FilterButton,
  ApprovalStatusButton
} from "../../../../components"
import { useHistory } from "react-router-dom"
import { DateConvert, TableNumber } from "../../../../utilities"
import { ModalFilter } from "./section"
import { ComplimentApi } from "api"

const ListCompliment = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  let createAlert = location?.state?.variant

  const [isLoading, setIsLoading] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")
  const [data, setData] = useState([
    {
        id_compliment: 1,
        tgl_compliment: "2022-01-19",
        no_compliment: "2023/05/INV/PR/000001",
        nama_customer: "Riski Adi Pratama",
        nama_petugas: "Petugas Riski",
        item_barang: [
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            },
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            },
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            },
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            },
        ],
        keterangan: "Keterangan Riski",
        status_approval: "REV"
    },
    {
        id_compliment: 1,
        tgl_compliment: "2022-01-19",
        no_compliment: "2023/05/INV/PR/000001",
        nama_customer: "Riski Adi Pratama",
        nama_petugas: "Petugas Riski",
        item_barang: [
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            }
        ],
        keterangan: "Keterangan Riski",
        status_approval: "APP"
    },
    {
        id_compliment: 1,
        tgl_compliment: "2022-01-19",
        no_compliment: "2023/05/INV/PR/000001",
        nama_customer: "Riski Adi Pratama",
        nama_petugas: "Petugas Riski",
        item_barang: [
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            }
        ],
        keterangan: "Keterangan Riski",
        status_approval: "PEN"
    },
    {
        id_compliment: 1,
        tgl_compliment: "2022-01-19",
        no_compliment: "2023/05/INV/PR/000001",
        nama_customer: "Riski Adi Pratama",
        nama_petugas: "Petugas Riski",
        item_barang: [
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            }
        ],
        keterangan: "Keterangan Riski",
        status_approval: "VER"
    },
    {
        id_compliment: 1,
        tgl_compliment: "2022-01-19",
        no_compliment: "2023/05/INV/PR/000001",
        nama_customer: "Riski Adi Pratama",
        nama_petugas: "Petugas Riski",
        item_barang: [
            {
                id_purchase_request : "83",
                id_item_buaso : "119",
                kode_item : "10.000096",
                nama_item : "Kotak kemasan minyak Ajik",
                nama_satuan : "Pcs",
                qty : "10000.00",
                qty_ordered : null
            }
        ],
        keterangan: "Keterangan Riski",
        status_approval: "REJ"
    },
  ])

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.com?.filter?.active,
      tgl_compliment_mulai: location?.state?.com?.filter?.tgl_compliment_mulai,
      tgl_compliment_selesai: location?.state?.com?.filter?.tgl_compliment_selesai,
      customer: location?.state?.com?.filter?.customer,
      petugas: location?.state?.com?.filter?.petugas,
      item_barang: location?.state?.com?.filter?.item_barang,
      status_approval: location?.state?.com?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.com?.filter?.page ?? "1",
      dataLength: location?.state?.com?.filter?.dataLength ?? "10",
      totalPage: location?.state?.com?.filter?.totalPage ?? "1",
      dataCount: location?.state?.com?.filter?.dataCount ?? "0",
    }
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const [modalStatus, setModalStatus] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [modalFilter, setModalFilter] = useState(false)
  const [readModalShow, setReadModalShow] = useState(false)
  const [idPurchase, setIdPurchase] = useState("")

// REQUEST DATA SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    Axios.all([ComplimentApi.getPage({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_compliment_mulai: dataFilter?.filter?.tgl_compliment_mulai,
      tgl_compliment_selesai: dataFilter?.filter?.tgl_compliment_selesai,
      customer: dataFilter?.filter?.customer,
      petugas: dataFilter?.filter?.petugas,
      item_barang: dataFilter?.filter?.item_barang,
      status_approval: dataFilter?.filter?.status_approval,
    })])
      .then(
        Axios.spread(res => {
          setData(res?.data?.data ?? [])
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            }
          })
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        })
        setShowAlert(true)
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        } else {
          setShowAlert(false)
        }
        setIsLoading(false)
      })
  }
// SEARCH HANDLER
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchKey(e.target.value)
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setShowAlert(key ? true : false)
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }
  useEffect(() => {
    setNavbarTitle("Compliment")

    if (createAlert !== undefined) {
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text })
      setShowAlert(true)
    } else {
      return getData()
    }

    getData()

    return () => {
      history.replace((createAlert = undefined))
      setIsLoading(false) // setIsSearching(false) // setShowAlert(false)
    }
  }, [
    setNavbarTitle, 
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_compliment_mulai,
    dataFilter?.filter?.tgl_compliment_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.petugas,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.status_approval, 
  ])

  const Table = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false)
  
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_item ?? "-"} ${brg.qty_compliment ? parseFloat(brg.qty_compliment) : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg.nama_item ?? "-"} ${brg.qty_compliment ? parseFloat(brg.qty_compliment) : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => { setisReadMoreClick((prev) => !prev)}}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ): ("")}
        </>
      )
    }
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : ""
      const convert = data.substring(0, 3)

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        }
      if (convert === "VER")
        return {
          variant: "outline-success",
          label: "VERIFIED",
        }
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISION",
        }
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        }
      return {
        variant: "outline-secondary",
        label: "PENDING",
      }
    }
    return (
      <>
        <span style={{ fontSize: "14px" }}><b>List Data Compliment</b></span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Compliment</ThFixed>
              <Th style={{ minWidth: "300px" }}>Customer</Th>
              <Th style={{ minWidth: "200px" }}>Petugas Pemberi Compliment</Th>
              <Th style={{ minWidth: "300px" }}>Item Barang</Th>
              <Th style={{ minWidth: "200px" }}>Keterangan</Th>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex">
                    <ReadButton  onClick={() => history.push("/inventory/transaksi/compliment/detail/" + val.id_compliment, { ...location?.state, com: dataFilter, detail: val })}/>
                    {val.status_approval === "REV" && (
                      <UpdateButton onClick={() => history.push("/inventory/transaksi/compliment/update/" + val.id_compliment, { ...location?.state, com: dataFilter, detail: val })} />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_compliment ? DateConvert(new Date(val.tgl_compliment)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_compliment ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_petugas ?? "-"}</Td>
                <Td>{val.detail ? val.detail.length > 0 ? <ItemBarangCollapse data={val.detail} /> : "-" : "-"}</Td>
                <Td>{val.keterangan ?? "-"}</Td>
                <TdFixed>
                  <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1 }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchKey} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/inventory/transaksi/compliment/create", { ...location?.state, com: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true 
        ? <DataStatus loading={true} text="Memuat data..." /> 
        : data.length > 0 
            ? <Table /> 
            : <DataStatus text="Tidak ada data" /> 
      }
      {modalFilter === true && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListCompliment