// React
import { useState, useEffect } from "react"
import { useHistory, useParams, useLocation } from "react-router-dom"

// Component
import { Card,  Modal } from "react-bootstrap"
import { 
  Alert, BackButton,  DataStatus, ActionButton, CRUDLayout
} from "components"
import { DateConvert } from "utilities"

// Icon
import { IoAlertCircleOutline } from 'react-icons/io5'

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// API
import Axios from 'axios'
import { SuratJalanApi } from 'api'

// Page Component
import { InfoDOSection, InfoSection, FormSection } from "../Section"

const TambahSuratJalan = ({setNavbarTitle}) => {
  const today = DateConvert(new Date()).default
  const history = useHistory()
  const {id} = useParams()
  const location = useLocation()
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dataArmada, setDataArmada] = useState([])
  const [dataGudang, setDataGudang] = useState([])
  const [dataPetugas, setDataPetugas] = useState([])
  const [formRendered, setFormRendered] = useState(0)
  const [showModalQty, setShowModalQty] = useState(false)
  const [startDate, setStartDate] = useState(null);
  // console.log("date", startDate)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const mappingDataTable = data => data.map(val => {
    const qtyDo = val.qty_do ? parseFloat(val.qty_do) : 0;
    const qtyTerkirim = val.qty_terkirim ? parseFloat(val.qty_terkirim) : 0;
    const qtySisaDo = parseFloat(val.qty_sisa_do ?? 0);

    return {
      id_delivery_order_detail: val.id_delivery_order_detail ?? null,
      id_delivery_order: val.id_delivery_order ?? null,
      kode_item: val.kode_barang ?? "-",
      nama_item: val.nama_barang ?? "-",
      satuan: val.nama_satuan ?? "",
      satuan_surat_jalan: val.satuan_jual ?? "",
      qty_do: qtyDo,
      qty_terkirim: qtyTerkirim,
      qty_sisa_do: qtySisaDo,
      qty_surat_jalan: 0,
      qty_satuan_pakai: 0,
      qty_menunggu_persetujuan: parseFloat(val.qty_menunggu_persetujuan ?? 0),
      stok_gudang: parseFloat(val.qty_stok_gudang ?? 0),
    }
  })
  const mappingDataPetugas = data => data.map(val => {
    return {
      label: val.nama_supir,
      value: val.id_supir
    }
  })
  const mappingDataArmada = data => data.map(val => {
    return {
      // label: `${val.plat_nomor} - ${val.nama_item_aset}`,
      // value: val.id_item_aset
      label: val.nama_kendaraan,
      value: val.id_kendaraan
    }
  })
  const mappingDataGudang = data => data.map(val => {
    return {
      label: val.nama_gudang,
      value: val.id_gudang
    }
  })

  const getInitialData = (date) => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      // SuratJalanApi.getSingleDO({id_delivery_order: id}),
      SuratJalanApi.getSingleDO({ id_delivery_order: id, tanggal: date }),
      SuratJalanApi.getDropdownPetugas(),
      SuratJalanApi.getDropdownArmada(),
      SuratJalanApi.getGudang(),
    ])
      .then(Axios.spread((dataDO, petugas, armada, gudang) => {
        const getData = dataDO.data.data
        const getPetugas = petugas.data.data
        const getArmada = armada.data.data
        const getGudang = gudang.data.data

        const mapDataTable = mappingDataTable(getData.detail ?? [])
        const mapDataPetugas = mappingDataPetugas(getPetugas ?? [])
        const mapDataArmada = mappingDataArmada(getArmada ?? [])
        const mapDataGudang = mappingDataGudang(getGudang ?? [])

        setDataInfo(getData)
        setDataTable(mapDataTable)
        setDataPetugas(mapDataPetugas)
        setDataArmada(mapDataArmada)
        setDataGudang(mapDataGudang)
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const formInitialValues = {
    // tanggal: today,
    tanggal: "",
    nomor: "",
    petugas_pengiriman: "",
    armada_pengiriman: "",
    catatan_surat_jalan: "",
    is_konsinyasi: false,
    id_gudang_tujuan_konsinyasi: null,
  }
  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
    petugas_pengiriman: Yup.string().required("Pilih petugas pengiriman"),
    armada_pengiriman: Yup.string().required("Pilih armada pengiriman"),
  })
  const formSubmitHandler = (values, {setSubmitting}) => {
    const checkQtyNull = Boolean(dataTable.find(val => val.qty_surat_jalan < 1))
    const checkQty = Boolean(dataTable.find(val => val.qty_surat_jalan > val.qty_sisa_do))
    const tableValues = dataTable.map(val => {
      return {
        id_delivery_order_detail: val.id_delivery_order_detail,
        id_delivery_order: val.id_delivery_order,
        qty_surat_jalan: val.qty_surat_jalan,
        qty_satuan_jual: val.qty_satuan_pakai
      }
    })
    const finalValues = {
      id_delivery_order: id,
      id_supir: values.petugas_pengiriman,
      id_petugas_gudang: values.petugas_pengiriman,
      id_kendaraan: values.armada_pengiriman,
      id_gudang_tujuan_konsinyasi: values.id_gudang_tujuan_konsinyasi,
      is_konsinyasi: values.is_konsinyasi,
      tgl_surat_jalan: values.tanggal,
      no_surat_jalan: values.nomor,
      catatan_surat_jalan: values.catatan_surat_jalan,
      detail: tableValues
    }

    if (checkQtyNull || checkQty) {
      setSubmitting(false)
      setShowModalQty(true)
    } else {
      SuratJalanApi.save(finalValues)
        .then(() => {
          history.push("/inventory/transaksi/surat-jalan", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil disimpan!"
            }
          })
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal disimpan!"
          })
        })
        .finally(() => setSubmitting(false))
    }
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Surat Jalan")

    // eslint-disable-next-line
  }, [])

  const ModalQty = () => (
    <Modal 
      show={showModalQty}
      onHide={() => setShowModalQty(false)}
    >
      <Modal.Header closeButton>
        <b className="text-danger">Peringatan</b>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="mb-3">
          <IoAlertCircleOutline size={75} className="mb-2 text-danger" /><br />
          <b>Qty. Surat Jalan Tidak Valid!</b><br />
          <small><b>QTY. SURAT JALAN</b> tidak boleh lebih dari <b>QTY. SISA DO</b> dan tidak boleh bernilai 0!</small>
        </p>
      </Modal.Body>
    </Modal>
  )

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {formik => (
        <CRUDLayout>
          <CRUDLayout.Head>
            <CRUDLayout.HeadButtonSection>
              <BackButton onClick={() => history.push("/inventory/transaksi/surat-jalan/delivery-order", { ...location?.state })} />
            </CRUDLayout.HeadButtonSection>
          </CRUDLayout.Head>
          <div className="font-weight-bold mb-2">Tambah Data Surat Jalan</div>
          <Card style={{minHeight: "80vh"}}>
            <Card.Body>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => setAlertConfig({
                  ...alertConfig,
                  show: false
                })}
              />
              {fetchingStatus.loading
                ? <DataStatus loading text="Memuat . . ." /> 
                : fetchingStatus.success
                  ? <form onSubmit={formik.handleSubmit}>
                      <InfoSection data={dataInfo} />
                      <InfoDOSection data={dataInfo} />
                      <FormSection 
                        formik={formik} 
                        formRendered={formRendered}
                        fetchingStatus={fetchingStatus}
                        setFormRendered={setFormRendered}
                        dataArmada={dataArmada}
                        dataPetugas={dataPetugas}
                        dataGudang={dataGudang}
                        dataTable={dataTable}
                        setDataTable={setDataTable}
                        getInitialData={getInitialData}
                        // startDate={startDate}
                        // setStartDate={setStartDate}
                        type='create'
                      />

                      <div className="d-flex justify-content-end">
                        <ActionButton 
                          type="submit"
                          className="mt-5"
                          text="Simpan"
                          loading={formik.isSubmitting}
                        />
                      </div>
                    </form>
                  : <DataStatus text="Data gagal dimuat" />
              }
            </Card.Body>
            <ModalQty />
          </Card>
        </CRUDLayout>
      )}
    </Formik>
  )
}

export default TambahSuratJalan
