import Services from "services"

class TransferBahanProduksiApi {
  getTransfer(params) {
    return Services.get("/transfer_produksi_bahan/transfer", { params })
  }

  // getTransfer(params) {
  //   return Services.get("/transfer_produksi_bahan/page", { params })
  // }

  getPermintaan(params) {
    return Services.get("/transfer_produksi_bahan/page", { params })
  }
  
  getSingle(params) {
    return Services.get("/transfer_produksi_bahan/single", { params })
  }
  
  getKode(params) {
    return Services.get("/transfer_produksi_bahan/no_baru", { params })
  }

  getDropdown(params) {
    return Services.get("/transfer_produksi_bahan/dropdown", { params })
  }

  getQtyGudang(params) {
    return Services.get("/transfer_produksi_bahan/qty_booking", { params })
  }
  
  save(data) {
    return Services.post("/transfer_produksi_bahan", data)
  }
}

export default new TransferBahanProduksiApi();
