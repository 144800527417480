// Config
import Logo from "config";

const KopSuratSection = () => (
  <div className="d-flex mx-2">
    <img src={Logo.LOGO} alt="" width={110} />
    <div style={{ marginLeft: 30 }}>
      <div className="font-weight-bold">CV. KRISNA ADVENTURE</div>
      <div>JL. RAYA SAMBANGAN, ALING - ALING WATERFALL, SINGARAJA - BALI</div>
      <div>Telp. : +62 877 6245 3130</div>
      <div>Email : krisnaadventures@gmail.com</div>
    </div>
  </div>
);

export default KopSuratSection;
