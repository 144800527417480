import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Card, DropdownButton, Dropdown, Spinner } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  FilterButton,
  InfoItemHorizontal
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { TimeStamp } from "./Function"
import { KartuStockMaterialApi } from "api";
import { ModalFilter } from "./Section"
// import FileSaver from "file-saver"; 
import { saveAs } from 'file-saver'

const KartuStockMaterial = ({ setNavbarTitle }) => {

  const [isLoading, setIsLoading] = useState(true)
  const [showModalFilter, setShowModalFilter] = useState(false) 
  const [loadingExport, setLoadingExport] = useState(false)

// MENYIMPAN DATA INFO SECTION
  const [head, setHead] = useState({})

// MENYIMPAN LIST DATA TABLE
  const [table, setTable] = useState({
    body: [],
  })
  const [dataFilter, setDataFilter] = useState({
    active: false,
    dari: undefined,
    sampai: undefined,
    id_jenis: undefined,
    id_barang: undefined,
    id_gudang: undefined,
    id_kelompok: undefined,
  })

// INITIALIZE SALDO AWAL
  let saldoAwal = table.saldo_awal ?? 0
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

// USE EFFECT
  useEffect(() => {
    setNavbarTitle("Kartu Stock Barang")

    return () => {
      setIsLoading(false)
    }
  }, [setNavbarTitle])
  useEffect(() => {
    saldoAwal = table.saldo_awal
  }, [table.saldo_awal])

  const getData = () => {
    setIsLoading(true)

    KartuStockMaterialApi.getKartuStockMaterial({
      ...dataFilter,
      active: undefined
    }).then((res) => {
      setHead(res.data.data.head)
      setTable(res.data.data.table)
    }).catch(() => {
      setAlertConfig({
        variant: "danger",
        text: "Data gagal dimuat, tidak dapat menampilkan data",
      })
    }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getData()
  }, [dataFilter])

// EXPORT DATA
  const getExportData = (tipe) => {
    setLoadingExport(true)

    KartuStockMaterialApi.getExport({
      ...dataFilter,
      active: undefined,
      tipe
    }).then(val => {
        // const urlFile = process.env.REACT_APP_FILE_BASE_URL + val.data.file;
        // FileSaver.saveAs(urlFile, val.data.file);
        const link = val?.data?.data
        const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
        saveAs(parse, "PurchaseRequest")
    }).catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
    }).finally(() => setLoadingExport(false))
  }
  
// FUNGSI UNTUK MENGHITUNG SISA
  const hitungSisa = (item) => {
    if (parseInt(item.jumlah) > 0) {
      saldoAwal += parseInt(item.jumlah)
      item.sisa = parseInt(saldoAwal)
    } else {
      saldoAwal = saldoAwal - Math.abs(parseInt(item.jumlah))
      item.sisa = parseInt(saldoAwal)
    }
  }

// TABLE SECTION
  const InfoSection = () => {
    return (
      <Card className="mb-4">
        <Card.Body>
          <table>
            <tbody>
              <InfoItemHorizontal label="Kode Barang" text={isLoading === true ? "memuat..." : head.kode_barang ?? "-"} />
              <InfoItemHorizontal label="Nama Barang" text={isLoading === true ? "memuat..." : head.nama_barang ?? "-"} />
              <InfoItemHorizontal label="Gudang" text={isLoading === true ? "memuat..." : head.nama_gudang ?? "-"} />
            </tbody>
          </table>
        </Card.Body>
      </Card>
    );
  }
  const DataKartuStock = () => {
    return (
      <>
        <span style={{fontSize:"14px"}}><b>List Data Kartu Stok Barang</b></span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tanggal</ThFixed>
              <ThFixed>No. Referensi</ThFixed>
              <Th>Keterangan</Th>
              <Th width={100}>Debet</Th>
              <Th width={100}>Kredit</Th>
              <Th width={100}>Sisa</Th>
            </Tr>
          </THead>
          <TBody>
            {table?.body && table?.body?.length > 0 ? (
              table.body.map((val, index) => {
                hitungSisa(val);
                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>
                      <div className="text-left">{DateConvert(new Date(val.created_at)).defaultDMY}</div>
                      <div className="text-left">{TimeStamp(val.created_at)}</div>
                    </TdFixed>
                    <TdFixed><div className="text-left">{val.no_ref_transaksi}</div></TdFixed>
                    <Td>{val.keterangan ?? "-"}</Td>
                    <Td className="text-right">{parseInt(val.jumlah) > 0 ? RupiahConvert(String(val.jumlah)).withoutLabel : 0}</Td>
                    <Td className="text-right">{parseInt(val.jumlah) < 0 ? RupiahConvert(String(Math.abs(val.jumlah))).withoutLabel : 0}</Td>
                    <Td className="text-right">{RupiahConvert(String(val.sisa)).withoutLabel}</Td>
                  </Tr>
                );
              })
            ) : <Tr><td colSpan={7} className="text-center">{" "}Tidak ada data</td></Tr>  }
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
          <div className="d-flex mb-2 ml-2">
              <FilterButton 
                  active={dataFilter?.active}
                  onClick={() => setShowModalFilter(true)}
              />
              <DropdownButton 
                className='ml-3'
                variant='warning text-white'
                disabled={loadingExport ? true : false}
                title={loadingExport
                  ? <>
                      <Spinner animation="border" size="sm" />
                      <span className="ml-2 mr-2  ">Loading...</span>
                    </>
                  : <span className="text-white mr-2">EXPORT DATA</span>
                }
              >
                <Dropdown.Item onClick={() => getExportData('excel')}>Excel (.xlsx)</Dropdown.Item>
                <Dropdown.Item onClick={() => getExportData('pdf')}>PDF (.pdf)</Dropdown.Item>
              </DropdownButton>
          </div>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      <InfoSection />
      {isLoading === true 
        ? <DataStatus loading={true} text="Memuat data..." />
        : table?.body && table?.body?.length > 0
          ? <DataKartuStock />
          : <div></div>
      }
      {/* <DataKartuStock /> */}
      
      {/* Modal Filter */}
      { showModalFilter && <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          getData={getData}
        />
      }
    </CRUDLayout>
  )
}

export default KartuStockMaterial


// tipe: tipe
// dari: dataFilter?.dari,
// sampai: dataFilter?.sampai,
// id_jenis: dataFilter?.id_jenis,
// id_barang: dataFilter?.id_barang,
// id_gudang: dataFilter?.id_gudang,
// id_kelompok: dataFilter?.id_kelompok,

// const link = res?.data?.file
// const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
// FileSaver.saveAs(link, "Kartu Stok Barang")
// INITIAL DATE OBJ
  // const date = new Date()
  // const formikRef = useRef()
  // const history = useHistory()