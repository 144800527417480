import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import _ from "lodash"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton
} from "components"
import { DateConvert, TableNumber } from "utilities"
import { SuratJalanApi } from "api"
import { ModalFilter } from "../Section"

const ListSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [modalFilter, setModalFilter] = useState(false)
  const [dataSuratJalan, setDataSuratJalan] = useState([])
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`
  }
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER)) : {}
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION)) : {}
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_surat_jalan_mulai: filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: filter?.tgl_delivery_order_selesai,
      tgl_batas_waktu_mulai: filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: filter?.tgl_batas_waktu_selesai,
      petugas_pengirim: filter?.petugas_pengirim,
      armada_pengirim: filter?.armada_pengirim,
      gudang: filter?.gudang,
      nama_item: filter?.nama_item,
      jenis_surat_jalan: filter?.jenis_surat_jalan,
      status_approval: filter?.status_approval,
    },
    pagination: {
      page: pagination?.page ?? "1",
      dataLength: pagination?.dataLength ?? "10",
      totalPage: pagination?.totalPage ?? "1",
      dataCount: pagination?.dataCount ?? "0",
    }
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    SuratJalanApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter
    })
      .then(( data ) => {
        setDataSuratJalan(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }
  useEffect(() => {
    setNavbarTitle("Surat Jalan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter,
  ])

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
  
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_barang} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg.nama_barang} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => { setisReadMoreClick((prev) => !prev)}}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ): ("")}
        </>
      );
    }
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      if (convert === "VER")
        return {
          variant: "outline-verified",
          label: "VERIFIED",
        };
      if (convert === "REV")
        return {
          variant: "outline-revised",
          label: "REVISION",
        };
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        };
      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
    }
    const DataTable = () => (
      <>
        <b style={{ fontSize: "14px" }}>List Data Surat Jalan</b>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Surat Jalan</ThFixed>
              <ThFixed>Informasi Delivery Order</ThFixed>
              <Th style={{minWidth:"200px"}}>Petugas Pengiriman</Th>
              <Th style={{minWidth:"200px"}}>Armada Pengiriman</Th>
              <Th style={{minWidth:"200px"}}>Gudang Asal</Th>
              <Th style={{minWidth:"300px"}}>Item Barang</Th>
              <Th style={{minWidth:"200px"}}>Jenis Surat Jalan</Th>
              {/* <ThFixed>Tgl. Batas Waktu</ThFixed> */}
              <ThFixed>Status Approval</ThFixed>
            </tr>
          </thead>
          <tbody>
            {dataSuratJalan.map((val, index) => (
              <tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex">
                    <ReadButton 
                      onClick={() => {
                        history.push("/inventory/transaksi/surat-jalan/detail/" + val.id_surat_jalan, { infoValue: val })
                        sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
                        sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))
                      }}
                    />
                    {(val.status_approval === "REV") && (
                        <UpdateButton 
                          onClick={() => {
                            history.push("/inventory/transaksi/surat-jalan/ubah/" + val.id_surat_jalan, { infoValue: val })
                            sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
                            sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))
                          }} 
                        />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_surat_jalan ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_surat_jalan ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_delivery_order ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_delivery_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_sopir ?? "-"}</Td>
                <Td>{`${val.plat_nomor} - ${val.nama_item_aset}`}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>{val.item_barang ? val.item_barang.length > 0 ? <ItemBarangCollapse data={val.item_barang} /> : "-" : "-"}</Td>
                <Td>{val.is_konsinyasi ? `Konsinyasi | ${val.nama_gudang ?? ""}` : "Penjualan"}</Td>
                {/* <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</TdFixed> */}
                <TdFixed>
                  <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )

    if (!dataSuratJalan || dataSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => {
            history.push('/inventory/transaksi/surat-jalan/delivery-order')
            sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
            sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))
          }} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

      {modalFilter === true && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          SESSION_KEY={SESSION_KEY}
        />
      )}
    </CRUDLayout>
  )
}

export default ListSuratJalan
