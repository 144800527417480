import Services from "../../../services";

class MutasiAntarGudangApi {
  getPage(params) {
    return Services.get("mutasi_antar_gudang/page", { params });
  }

  getNomor(params) {
    return Services.get("mutasi_antar_gudang/no_baru", { params });
  }

  getDropdown(params) {
    return Services.get("mutasi_antar_gudang/dropdown", { params });
  }

  getStockUpdate(params) {
    return Services.get("mutasi_antar_gudang/qty_booking", { params });
  }

  getDropdownItemBarang() {
    return Services.get("dropdown/item_barang");
  }

  getDropdownGudang() {
    return Services.get("dropdown/gudang");
  }

  getDropdownSatuan() {
    return Services.get("dropdown/satuan");
  }

  getSingle(params) {
    return Services.get("/mutasi_antar_gudang/single/", { params })
  }

  save(type, data) {
    if (type === "create") return Services.post("mutasi_antar_gudang", data);
    if (type === "update") return Services.put("mutasi_antar_gudang", data);
  }
}

export default new MutasiAntarGudangApi();
