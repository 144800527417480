import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'
import { DeliveryOrderApi } from 'api'
import Axios from 'axios'
import { useEffect } from "react"

export const ModalFilterSO = ({ show, setShow, data, setData }) => {
  const [dropdownCustomer, setDropdownCustomer] = useState([{ label: 'Semua', value: undefined }])
  const [dropdownGudang, setDropdownGudang] = useState([{ label: 'Semua', value: undefined }])
  const [loading, setLoading] = useState(true);

// REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      DeliveryOrderApi.getDropdownCustomer(),
      DeliveryOrderApi.getDropdownGudang(),
    ])
      .then(
        Axios.spread((customer, gudang) => {
          const mapDataCustomer = customer?.data?.data ? customer.data.data.map((val) => ({ label: val?.nama_customer, value: val?.id_customer })) : [];
          const mapDataGudang = gudang?.data?.data ? gudang.data.data.map((val) => ({ label: val?.nama_gudang, value: val?.id_gudang })) : [];

          setDropdownCustomer([...dropdownCustomer, ...mapDataCustomer]);
          setDropdownGudang([...dropdownGudang, ...mapDataGudang]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  const formInitialValues = {
    tgl_sales_order_mulai: data?.filter?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: data?.filter?.tgl_sales_order_selesai,
    tgl_batas_waktu_mulai: data?.filter?.tgl_batas_waktu_mulai,
    tgl_batas_waktu_selesai: data?.filter?.tgl_batas_waktu_selesai,
    customer: data?.filter?.customer,
    gudang: data?.filter?.gudang,
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
       })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
         })
    }

    setShow(false)
  }
  const onChangeSO = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_sales_order_mulai: startDate,
        tgl_sales_order_selesai: endDate,
    })
  }
  const onChangeBW = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_batas_waktu_mulai: startDate,
        tgl_batas_waktu_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_sales_order_mulai: undefined,
      tgl_sales_order_selesai: undefined,
      tgl_batas_waktu_mulai: undefined,
      tgl_batas_waktu_selesai: undefined,
      customer: undefined,
      gudang: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
      
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick(values, setValues)}
        onFilterButtonClick={handleSubmit}>

        <DatePicker
          selectsRange
          label="Tgl. Sales Order"
          dateFormat="dd/MM/yyyy"
          placeholderText="Pilih tanggal sales order"
          startDate={values.tgl_sales_order_mulai ? new Date(values.tgl_sales_order_mulai) : ''}
          endDate={values.tgl_sales_order_selesai ? new Date(values.tgl_sales_order_selesai) : ''}
          onChange={(dates) => onChangeSO(dates, values, setValues)}
          monthsShown={2}
        />
        <DatePicker
          selectsRange
          label="Tgl. Batas Waktu"
          dateFormat="dd/MM/yyyy"
          placeholderText="Pilih tanggal batas waktu"
          startDate={values.tgl_batas_waktu_mulai ? new Date(values.tgl_batas_waktu_mulai) : ''}
          endDate={values.tgl_batas_waktu_selesai ? new Date(values.tgl_batas_waktu_selesai) : ''}
          onChange={(dates) => onChangeBW(dates, values, setValues)}
          monthsShown={2}
        />
        <SelectSearch
          key={values.customer}
          label="Customer"
          placeholder="Pilih customer"
          defaultValue={dropdownCustomer.find(item => item.value === values.customer)}
          option={dropdownCustomer}
          onChange={val => setFieldValue('customer', val.value)}
          loading={loading}
        />
        <SelectSearch
          key={values.gudang}
          label="Gudang"
          placeholder="Pilih gudang"
          defaultValue={dropdownGudang.find(item => item.value === values.gudang)}
          option={dropdownGudang}
          onChange={val => setFieldValue('gudang', val.value)}
          loading={loading}
        />
      </FilterModal>
    )}
  </Formik>
  )
}
