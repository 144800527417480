import Services from '../../../services'

class KartuStockMaterialApi {
    getDropdownKelompok(params) {
        return Services.get('dropdown/kelompok_material')
    }

    getDropdownGudang() {
        return Services.get('dropdown/gudang')
    }

    // getDropdownJenis(params) {
    //     return Services.get('/dropdown/jenis_material', { params })
    // }
    getDropdownJenis(value) {
        return Services.get(`/dropdown/jenis_material?id_kelompok=${value}`)
    }

    // getDropdownMaterial(params) {
    //     return Services.get('/dropdown/material', { params })
    // }
    getDropdownMaterial(value) {
        return Services.get(`/dropdown/material?id_jenis=${value}`)
    }

    getKartuStockMaterial(params) {
        return Services.get('/kartu_stok', { params })
    }

    getExport(params) {
        return Services.get('/kartu_stok/export', { params })
    }
}

export default new KartuStockMaterialApi()
