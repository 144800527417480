// Components
import { NumberFormat } from "components"
import { Col, Row } from "react-bootstrap"

// Form
import { useFormikContext } from "formik"

const TabKonversi = ({ readOnly }) => {
    const { values, errors, touched, setFieldValue } = useFormikContext()

    return (
        <div>
            <Row>
                <Col lg={6}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <small>Satuan Beli</small>
                                </td>
                                <td />
                                <td>
                                    <small>Satuan Pakai</small>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <b>1 {values.kode_satuan_beli ? values.kode_satuan_beli : '-'}</b>
                                </td>
                                <td style={{ width: '25px' }} className="text-center"> = </td>
                                <td className="align-bottom">
                                    <div className="d-flex">
                                        <NumberFormat
                                            label=""
                                            noMargin
                                            placeholder="Satuan Pakai"
                                            value={values.nilai_konversi_beli_pakai}
                                            onChange={value => setFieldValue('nilai_konversi_beli_pakai', value)}
                                            error={Boolean(errors.nilai_konversi_beli_pakai && touched.nilai_konversi_beli_pakai)}
                                            errorText={Boolean(errors.nilai_konversi_beli_pakai && touched.nilai_konversi_beli_pakai) && errors.nama_item}
                                            disabled={readOnly}
                                        />
                                        <div className="mt-1 ml-3">{values.kode_satuan_pakai ? values.kode_satuan_pakai : '-'}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
            <Col lg={6}>
                <table className="mt-2">
                    <tbody>
                        <tr>
                            <td>
                                <small>Satuan Jual</small>
                            </td>
                            <td />
                            <td>
                                <small>Satuan Pakai</small>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right">
                                <b>1 {values.kode_satuan_jual ? values.kode_satuan_jual : '-'}</b>
                            </td>
                            <td style={{ width: '25px' }} className="text-center"> = </td>
                            <td className="align-bottom">
                                <div className="d-flex">
                                    <NumberFormat
                                        label=""
                                        noMargin
                                        placeholder="Satuan Pakai"
                                        value={values.nilai_konversi_jual_pakai}
                                        onChange={value => setFieldValue('nilai_konversi_jual_pakai', value)}
                                        error={Boolean(errors.nilai_konversi_jual_pakai && touched.nilai_konversi_jual_pakai)}
                                        errorText={Boolean(errors.nilai_konversi_jual_pakai && touched.nilai_konversi_jual_pakai) && errors.nama_item}
                                        disabled={readOnly}
                                    />
                                    <div className="mt-1 ml-3">{values.kode_satuan_pakai ? values.kode_satuan_pakai : '-'}</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
        </div>
    )
}

export default TabKonversi