import React from 'react'
import {
  Table,
  Th,
  Td,
} from "components"

const TableStokTerbaru = ({ data }) => {
  return (
    <Table>
      <thead>
        <tr>
          <Th width="12px">No</Th>
          <Th width="12px">Kode Item</Th>
          <Th>Item Bahan</Th>
          <Th width="200px">Kelompok Bahan</Th>
          <Th width="150px">Jenis Bahan</Th>
          <Th width="250px">Gudang</Th>
          <Th width="100px">Qty.</Th>
          <Th width="80px">Satuan</Th>
        </tr>
      </thead>
      <tbody>
        {!data || data.length > 0 ?
          data?.map((val, index) => (
          <tr key={index}>
            <Td className="align-middle text-nowrap text-center">{index + 1}</Td>
            <Td className="align-middle text-nowrap text-center">{val?.kode_bahan ?? "-"}</Td>
            <Td className="align-middle text-nowrap ">{val?.nama_bahan ?? "-"}</Td>
            <Td className="align-middle text-capitalize text-nowrap"> {val?.kelompok_bahan ?? "-"} </Td>
            <Td className="align-middle text-nowrap">{val?.jenis_bahan ?? "-"}</Td>
            <Td className="align-middle">{val?.nama_gudang ?? "-"}</Td>
            <Td className="align-middle text-uppercase text-right text-nowrap"> {val?.qty ?? "0"} </Td>
            <Td className="align-middle text-uppercase text-nowrap"> {val?.satuan_pakai ?? "-"} </Td>
          </tr>
        )) : <tr><td colSpan={8} className="text-center">Tidak ada data</td></tr>
      }
      </tbody>
    </Table>
  )
}

export default TableStokTerbaru
