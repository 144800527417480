import { useState, useEffect, useRef } from "react"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { Card } from "react-bootstrap"
import { Alert, BackButton, DataStatus, ActionButton } from "components"
import { PenerimaanSuratJalanApi } from 'api'
import {
  InfoDOSection, InfoSection, InfoSJSection,
  TableSection, InfoPSJSection
} from "./Components"

// React Print
import { useReactToPrint } from "react-to-print"
import Print from "./Print"

const Detail = ({setNavbarTitle}) => {
  const history = useHistory()
  const {id} = useParams()
  const refPrint = useRef()
  const location = useLocation()
  const infoValue = location?.state?.infoValue?.tgl_surat_jalan

  const reactToPrint = useReactToPrint({ content: () => refPrint.current })
  const handlePrint = () => reactToPrint()

  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    PenerimaanSuratJalanApi.getSingle({ id_surat_jalan: id, tanggal: infoValue })
      .then(data => {
        const getData = data.data.data
        setDataInfo(getData)
        setDataTable(getData.detail)
        setFetchingStatus({
          loading: false,
          success: true
        })
      })
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Penerimaan Surat Jalan")

    // eslint-disable-next-line
  }, [])

  return (
    <Card style={{minHeight: "80vh"}}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Data Penerimaan Surat Jalan</b>

        <div>
          <ActionButton className='mx-1 mb-1' text="Cetak" onClick={handlePrint} />
          <BackButton onClick={() => history.goBack()} />
        </div>
      </Card.Header>
      <Card.Body className="pb-5">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({
            ...alertConfig,
            show: false
          })}
        />

        {fetchingStatus.loading ? (
          <DataStatus loading text="Memuat . . ." />
        ) : fetchingStatus.success ? (
          <>
            <InfoSection data={dataInfo} />
            <InfoDOSection data={dataInfo} />
            <InfoSJSection data={dataInfo} /><hr />
            <InfoPSJSection data={dataInfo} /><hr />
            <TableSection dataTable={dataTable} />

            <div className="d-flex justify-content-end">
              <div style={{ display: "none" }}>
                <Print ref={refPrint} idRef={id} data={dataInfo} dataTable={dataTable} />
              </div>
            </div>
          </>
        ) : (
          <DataStatus text="Data gagal dimuat" />
        )}
      </Card.Body>
    </Card>
  )
}

export default Detail
