// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  ReadModal,
  UpdateButton,
  FilterButton,
  ApprovalStatusButton
} from "../../../../components";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { PageNumber, DateConvert } from "../../../../utilities";
import { ModalFilter } from "./Section";

// API
import { PenerimaanBarangApi } from "../../../../api";

const PenerimaanBarang = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const title = "Penerimaan Barang";

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // data jenis
  const [data, setData] = useState([
    {
      qty_beli: "0.00",
    },
  ]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.penerimaan?.filter?.active,
      tgl_penerimaan_barang_mulai: location?.state?.penerimaan?.filter?.tgl_penerimaan_barang_mulai,
      tgl_penerimaan_barang_selesai: location?.state?.penerimaan?.filter?.tgl_penerimaan_barang_selesai,
      tgl_purchase_order_mulai: location?.state?.penerimaan?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: location?.state?.penerimaan?.filter?.tgl_purchase_order_selesai,
      vendor: location?.state?.penerimaan?.filter?.vendor,
      item_barang: location?.state?.penerimaan?.filter?.item_barang,
      status_approval: location?.state?.penerimaan?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.penerimaan?.filter?.page ?? "1",
      dataLength: location?.state?.penerimaan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.penerimaan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.penerimaan?.filter?.dataCount ?? "0",
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [dataDetail, setDataDetail] = useState({
    id_purchase: 0,
  });

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    PenerimaanBarangApi.getPage({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_penerimaan_barang_mulai: dataFilter?.filter?.tgl_penerimaan_barang_mulai,
      tgl_penerimaan_barang_selesai: dataFilter?.filter?.tgl_penerimaan_barang_selesai,
      tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: dataFilter?.filter?.tgl_purchase_order_selesai,
      vendor: dataFilter?.filter?.vendor,
      item_barang: dataFilter?.filter?.item_barang,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        alert('data gagal dimuat!')
        setData([])
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

// SEARCH HANDLER
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchKey(e.target.value)
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setShowAlert(key ? true : false)
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    setIsLoading(true);

    // nilai dari form search
    const key = searchKey;

    // lakukan search data ke server pada kode dibawah [

    PenerimaanBarangApi.search(key)
      .then((res) => {
        setData(res.data.data);
        // setTotalPage(res.data.total_page);
      })
      .catch((err) => alert(err.response.data.message))
      .finally(() => {
        setIsSearching(true);
        setIsLoading(false);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle, 
    searchKey,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penerimaan_barang_mulai,
    dataFilter?.filter?.tgl_penerimaan_barang_selesai,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.vendor,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.status_approval,
  ]);

  // MODAL UBAH COMPONENT
  const DetailModal = () => {
    const [readData, setReadData] = useState({
      qty_beli: "0.00",
      qty_pakai: "0.00",
      tgl_purchase_order: "",
      tgl_penerimaan_barang: "",
      qty_order: "0.00",
    });
    const [loadingModal, setLoadingModal] = useState(false);

    const getDetail = () => {
      if (dataDetail.id_purchase != 0) {
        PenerimaanBarangApi.getSinglePenerimaan("id_penerimaan_barang", dataDetail.id_penerimaan)
          .then((res) => setReadData(res.data.data))
          .catch((err) => alert(err.response.data.message))
          .finally(() => setLoadingModal(false));
      }
    };

    useEffect(() => {
      setLoadingModal(true);
      getDetail();
    }, [dataDetail]);

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Penerimaan Barang</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {readData.stakeholder === undefined
                ? "Memuat Data"
                : readData.stakeholder.map(
                    (val, index) =>
                      index !== 0 &&
                      val.status_approval !== "PEN" && (
                        <Col sm>
                          <InfoItem
                            title1={
                              val.status_approval === "VER" || val.status_approval === "REV"
                                ? `Pemeriksa ${
                                    val.approval_level !== "0" ? val.approval_level : ""
                                  }`
                                : val.status_approval === "APP"
                                ? "Pengesah"
                                : "Di Tolak Oleh"
                            }
                            value1={val.nama_karyawan ?? "-"}
                            title2="Catatan"
                            value2={val.catatan ?? "-"}
                          />
                        </Col>
                      )
                  )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ShowData = ({ title, text, line }) => (
      <div>
        <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
          {title}
        </small>
        <div className="text-dark">
          <small>{text ? text : "-"}</small>
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <ReadModal size="lg" show={showModal} onHide={() => setShowModal(false)} title={title}>
        <Modal.Body>
          {loadingModal === true ? (
            <>
              <DataStatus loading={true} text="Memuat data..." />
            </>
          ) : (
            <>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Purchase Order"
                    line={true}
                    text={
                      readData.tgl_purchase_order
                        ? DateConvert(new Date(readData.tgl_purchase_order)).detail
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Purchase Order"
                    line={true}
                    text={readData.no_purchase_order ? readData.no_purchase_order : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Penerimaan Barang"
                    line={true}
                    text={
                      readData.tgl_penerimaan_barang
                        ? DateConvert(new Date(readData.tgl_penerimaan_barang)).detail
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Penerimaan Barang"
                    line={true}
                    text={readData.no_penerimaan_barang ? readData.no_penerimaan_barang : "-"}
                  />
                </Col>
              </Row>
              <Row>
                {" "}
                <Col lg="12">
                  <ShowData title="Item" line={true} text={readData.nama_item} />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <ShowData
                    title="Vendor"
                    line={true}
                    text={readData.nama_vendor ? readData.nama_vendor : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="No. Surat Jalan"
                    line={true}
                    text={readData.no_surat_jalan ? readData.no_surat_jalan : "-"}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Petugas Penerima"
                    line={true}
                    text={readData.nama_karyawan ? readData.nama_karyawan : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Gudang Penerima"
                    line={true}
                    text={readData.nama_gudang ? readData.nama_gudang : "-"}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Qty Purchase Order"
                    line={true}
                    text={
                      readData.qty_order
                        ? readData.qty_order.replace(".", ",") + " " + readData.nama_satuan_order
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Beli"
                    line={true}
                    text={
                      readData.qty_beli ? `${readData.qty_beli} ${readData.nama_satuan_beli}` : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Pakai"
                    line={true}
                    text={
                      readData.qty_pakai
                        ? `${readData.qty_pakai} ${readData.nama_satuan_pakai}`
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <div className="row justify-content-center mt-2">
                <Col lg="10">
                  <div className="text-center">
                    <img src={readData.foto_surat_jalan} className="img-fluid" width="400" />
                  </div>
                </Col>
              </div>
              <FormCard />
            </>
          )}
        </Modal.Body>
      </ReadModal>
    );
  };

  // Tabel
  const Table = () => {
    const checkStatus = (stat) => {
      let data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP") {
        // return "APPROVED";
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      }
      if (convert === "VER") {
        // return "VERIFIED";
        return {
          variant: "outline-success",
          label: "VERIFIED",
        };
      }
      if (convert === "REV") {
        // return "REVISI";
        return {
          variant: "outline-warning",
          label: "REVISION",
        };
      }
      if (convert === "REJ") {
        // return "REJECT";
        return {
          variant: "outline-danger",
          label: "REJECTED",
        };
      }

      // return "PENDING";
      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
    };

    const checkEditButton = (status) => {
      const stat = checkStatus().label(status);

      if (stat.toUpperCase() === "REVISI") {
        return true;
      }

      // if (stat.toUpperCase() === "PENDING") {
      //   return true;
      // }

      return false;
    };

    return (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Penerimaan Barang</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Penerimaan Barang</ThFixed>
              <ThFixed>Informasi Purchase Order</ThFixed>
              <Th width={200}>Vendor</Th>
              <Th>Item Barang</Th>
              <Th width={100}>Qty</Th>
              {/* <Th>Satuan Beli</Th> */}
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={val.id_penerimaan_barang}>
                <TdFixed>
                  {PageNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex flex-row">
                    <ReadButton
                      onClick={() => {
                        setShowModal(true);
                        setDataDetail({
                          id_purchase: val.id_purchase_order_detail,
                          id_penerimaan: val.id_penerimaan_barang,
                        });
                      }}
                    />
                    {val.status_approval === "REV" && (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/penerimaan-barang/ubah/${val.id_penerimaan_barang}`,
                            { id_purchase_order_detail: val.id_purchase_order_detail,
                              ...location?.state, penerimaan: dataFilter
                            }
                          )
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_penerimaan_barang ? DateConvert(new Date(val.tgl_penerimaan_barang)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_penerimaan_barang ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_purchase_order ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_purchase_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_vendor ?? "-"}</Td>
                <Td>
                  {/* {val.dimensi === "3"
                    ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                    : val.dimensi === "0"
                      ? val.nama_item
                      : val.dimensi === "1"
                        ? `${val.nama_item} (${val.pj})`
                        : val.dimensi === "2"
                          ? `${val.nama_item} (${val.pj} X ${val.lb})`
                          : ""} */}
                  {val.nama_item ?? "-"}
                </Td>
                <Td><div className="text-right">{val.qty_beli ? val.qty_beli.replace(".", ",") : "0"}</div></Td>
                {/* <Td>{val.nama_satuan_beli ?? "-"}</Td> */}
                <TdFixed>
                  <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchKey} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push(`/inventory/transaksi/penerimaan-barang/list`, {
                ...location.state,
                penerimaan: dataFilter,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false);
          // CLEAR HISTORY LOCATION
          history.replace("/inventory/transaksi/penerimaan-barang", {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      <DetailModal />
    </CRUDLayout>
  );
};

export default PenerimaanBarang;
