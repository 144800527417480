import React, { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Card, Row, Col } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import Axios from "axios"
import {
  CRUDLayout,
  Input,
  ActionButton,
  DataStatus,
  DeleteButton,
  Alert,
  SelectSearch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  TextArea,
  Table
} from "components"
import { ModalTambahBarang } from "./section"
import { RupiahConvert } from "utilities"
import { ComplimentApi } from "api"

const ComplimentUpdate = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const {id} = useParams()
  const tglCompliment = location?.state?.detail?.tgl_compliment

  const [isLoading, setIsLoading] = useState(false)
  const [modalBarangConfig, setModalBarangConfig] = useState(false)
  const [valueItem, setValueItem] = useState([])
  const [processedData, setProcessedData] = useState([])
  const [listBarang, setListBarang] = useState([])
  const [dataDelete, setDataDelete] = useState([])
  console.log("listBarang", listBarang)
  console.log("valueItem", dataDelete)
  const [dropdownCustomer, setDropdownCustomer] = useState([])
  const [dropdownKaryawan, setDropdownKaryawan] = useState([])
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    show: false,
    text: "",
  })

  const getData = async () => {
    setIsLoading(true)

    Axios.all([
      ComplimentApi.getDropdownCustomer(),
      ComplimentApi.getDropdownKaryawan(),
      ComplimentApi.getSingle({ id_compliment: id, tanggal: tglCompliment }),
    ])
      .then(
        Axios.spread((resCustomer, resKaryawan, read) => {
          const customer = resCustomer?.data?.data ? resCustomer?.data?.data?.map((val) => Object({ value: val.id_customer, label: val.nama_customer })) : []
          const karyawan = resKaryawan?.data?.data ? resKaryawan?.data?.data?.map((val) => Object({ value: val.id_karyawan, label: val.nama_karyawan })) : []

          setDropdownCustomer(customer)
          setDropdownKaryawan(karyawan)
          setProcessedData(read?.data?.data ?? [])
          setListBarang(read?.data?.data?.detail)
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false))
  }
  useEffect(() => {
    setNavbarTitle("Compliment")
    getData()
  }, [])

  const formInitialValues = {
    id_compliment: processedData?.id_compliment ?? "",
    tgl_compliment: processedData?.tgl_compliment ?? "",
    no_compliment: processedData?.no_compliment ?? "",
    id_customer: processedData?.id_customer ?? "",
    id_petugas: processedData?.id_petugas ?? "",
    keterangan: processedData?.keterangan ?? "",
    id_detail_deleted: dataDelete ?? "",
  }
  const formValidationSchema = Yup.object().shape({
    tgl_compliment: Yup.string().required("Tanggal Compliment Harus Diisi"),
    id_customer: Yup.string().required("Pilih Customer"),
    id_petugas: Yup.string().required("Pilih Petugas"),
  })
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (listBarang?.length > 0) {
      let finalListCompliment = []

      listBarang?.map((obj) =>
        finalListCompliment?.push({
          qty_compliment: obj?.qty_compliment ?? obj?.qty_belanja,
          harga_satuan: parseInt(obj?.harga_satuan ?? obj?.harga_terbaru ?? 0),
          id_gudang: obj?.id_gudang,
          id_item_buaso: obj?.id_barang,
          id_compliment_detail: obj?.id_compliment_detail ?? valueItem?.id_compliment_detail ?? null,
        })
      )

      const finalValues = {
        ...values,
        detail: finalListCompliment,
      }

      ComplimentApi.update(finalValues)
        .then(() => history.push("/inventory/transaksi/compliment", {
          registerAlertConfig: {
            variant: "primary",
            show: true,
            text: "Tambah data berhasil!"
          }
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          show: true,
          text: `Tambah data gagal! (${err.response.data.message})`
        }))
        .finally(() => setSubmitting(false))

    } else {
      setSubmitting(false)
      setAlertConfig({
        variant: "danger",
        show: true,
        text: 'List Barang tidak boleh kosong!',
      })
    }
  }

  const total = listBarang?.reduce((acc, val) => Math.round(acc + parseFloat(val?.qty_compliment ?? val?.qty_belanja ?? 0) * parseFloat(val?.harga_satuan ?? val?.harga_terbaru ?? 0)), 0)
  const TableItemBarang = () => (
    <>
      <div className="mb-1" style={{ fontSize: "14px" }}><b>Item Compliment</b></div>
      <Table>
        <THead>
          <tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th>Item Barang</Th>
            <Th style={{ width: 200 }}>Gudang</Th>
            <Th style={{ width: 100 }}>Satuan</Th>
            <Th style={{ width: 100 }}>Qty. Compliment</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            <Th style={{ width: 150 }}>Sub Total</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </THead>
        <TBody>
          {listBarang?.map((item, index) => {
            const sub_total = Math.round(parseFloat(item?.qty_compliment ?? item?.qty_belanja ?? 0) * parseFloat(item.harga_satuan ?? item.harga_terbaru ?? 0))
            return (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{item.kode_item ?? item.kode_barang ?? "-"}</TdFixed>
                <Td>{item.nama_item ?? item.nama_barang ?? "-"}</Td>
                <Td>{item.nama_gudang ?? "-"}</Td>
                <Td>{item.nama_satuan ?? "-"}</Td>
                <Td><div className="text-right">{item.qty_compliment ? parseFloat(item.qty_compliment) : item.qty_belanja ? parseFloat(item.qty_belanja) : 0}</div></Td>
                <Td><div className="text-right">{item.harga_satuan ?? item.harga_terbaru ? RupiahConvert(parseInt(item.harga_satuan ?? item.harga_terbaru).toString()).getWithComa : "Rp. 0"}</div></Td>
                <Td className="text-right">{sub_total ? RupiahConvert(String(sub_total || 0)).getWithComa : "Rp.0"}</Td>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <DeleteButton
                      onClick={() => {
                        let filteredBarang = listBarang.filter((e, e_index) => e_index !== index)
                        setListBarang(filteredBarang)
                        setDataDelete([item?.id_compliment_detail])
                      }}
                    />
                  </div>
                </TdFixed>
              </Tr>
            )
          })}
          <Tr>
            <Td colSpan={7} className="text-right align-middle py-2 font-weight-bold">Total</Td>
            <Td className="text-right align-middle py-2 font-weight-bold">{total ? RupiahConvert(String(parseInt(total))).getWithComa : "Rp. 0"}</Td>
            <Td />
          </Tr>
        </TBody>
      </Table>
    </>
  )

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/inventory/transaksi/compliment", { ...location?.state })}/>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div className="font-weight-bold mb-2">Ubah Data Compliment</div>

      <CRUDLayout>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        <div>
          {!isLoading ?
              <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}>

                {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue, setSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Card className="pb-3 mt-3">
                        <Card.Body>
                          <Row>
                            <Col lg="6">
                              <Input
                                label="Tgl. Compliment"
                                type="date"
                                name="tgl_compliment"
                                value={values.tgl_compliment}
                                onChange={async (e) => {
                                  const value = e.target.value
                                  setFieldValue("tgl_compliment", value)
                                  setFieldValue("no_compliment", processedData?.no_compliment)
                                }}
                                error={errors.tgl_compliment && touched.tgl_compliment && true}
                                errorText={errors.tgl_compliment}
                              />
                            </Col>
                            <Col lg="6">
                              <Input
                                label="No. Compliment"
                                type="text"
                                name="no_compliment"
                                placeholder="No. Compliment"
                                readOnly={true}
                                value={values.no_compliment}
                                error={errors.no_compliment && touched.no_compliment && true}
                                errorText={errors.no_compliment}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <SelectSearch
                                label="Customer"
                                name="id_customer"
                                placeholder="Pilih Customer"
                                option={dropdownCustomer}
                                onChange={(val) => {setFieldValue("id_customer", val.value)}}
                                defaultValue={dropdownCustomer.find(item => item.value === values.id_customer)}
                                error={errors.id_customer && touched.id_customer && true}
                                errorText={errors.id_customer && touched.id_customer && errors.id_customer}
                              />
                            </Col>
                            <Col lg="6">
                              <SelectSearch
                                label="Petugas Pemberi Compliment*"
                                name="id_petugas"
                                placeholder="Pilih petugas"
                                onChange={(val) => setFieldValue("id_petugas", val.value)}
                                option={dropdownKaryawan}
                                defaultValue={dropdownKaryawan.find(item => item.value === values.id_petugas)}
                                error={errors.id_petugas && touched.id_petugas && true}
                                errorText={errors.id_petugas && touched.id_petugas && errors.id_petugas}
                              />
                            </Col>
                          </Row>
                          <TextArea
                            label="Catatan"
                            placeholder="Masukkan catatan"
                            name="keterangan"
                            value={values.keterangan}
                            onChange={handleChange}
                            error={errors.keterangan && touched.keterangan && true}
                            errorText={errors.keterangan}
                            rows={4}
                          />
                        </Card.Body>
                      </Card>

                      <div className="d-flex justify-content-end mt-4">
                        <ActionButton text='Tambah Barang' onClick={() => setModalBarangConfig(true)} />
                      </div>

                      <TableItemBarang />

                      <div className="d-flex justify-content-end">
                        <ActionButton
                          type="submit"
                          variant="success"
                          text="Ubah"
                          className="mt-2 px-4"
                          loading={isSubmitting}
                        />
                      </div>
                    </form>
                  )
                }}
              </Formik>
            : <DataStatus loading={isLoading} text='Memuat Data . . .' />
          }
        </div>
      </CRUDLayout>

      {modalBarangConfig && (
        <ModalTambahBarang
          listCompliment={listBarang}
          setListCompliment={setListBarang}
          modalBarangConfig={modalBarangConfig}
          setModalBarangConfig={setModalBarangConfig}
          valueItem={valueItem}
          setValueItem={setValueItem}
        />
      )}
    </CRUDLayout>
  )
}

export default ComplimentUpdate
