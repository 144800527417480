// React
import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"

// API
import Axios from "axios"
import { DeliveryOrderApi } from "../../../../api"

// Component
import {
  CRUDLayout, InputSearch, CreateButton, UpdateButton,
  ReadButton, DataStatus, Alert, Pagination, THead,
  TBody, Tr, ThFixed, TdFixed, Th, Td, ApprovalStatusButton,
  FilterButton
} from "../../../../components"
import { DateConvert, TableNumber } from "../../../../utilities"
import { ModalFilterDO } from "./comps"

const DeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true)

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")

  const history = useHistory()
  const location = useLocation()
  let createAlert = location?.state?.variant

  // data
  const [data, setData] = useState([])
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.do?.filter?.active,
      tgl_delivery_order_mulai: location?.state?.do?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: location?.state?.do?.filter?.tgl_delivery_order_selesai,
      tgl_sales_order_mulai: location?.state?.do?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: location?.state?.do?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: location?.state?.do?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: location?.state?.do?.filter?.tgl_batas_waktu_selesai,
      customer: location?.state?.do?.filter?.customer,
      gudang: location?.state?.do?.filter?.gudang,
      status_approval: location?.state?.do?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.do?.filter?.page ?? "1",
      dataLength: location?.state?.do?.filter?.dataLength ?? "10",
      totalPage: location?.state?.do?.filter?.totalPage ?? "1",
      dataCount: location?.state?.do?.filter?.dataCount ?? "0",
    }
  })

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // request data dari server
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    // request data ke server
    Axios.all([DeliveryOrderApi.getPage({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: dataFilter?.filter?.tgl_delivery_order_selesai,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: dataFilter?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: dataFilter?.filter?.tgl_batas_waktu_selesai,
      customer: dataFilter?.filter?.customer,
      gudang: dataFilter?.filter?.gudang,
      status_approval: dataFilter?.filter?.status_approval,
    })])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? [])
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            }
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        })
        setShowAlert(true)
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        } else {
          setShowAlert(false)
        }
        setIsLoading(false)
      })
  }

// SEARCH HANDLER
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchKey(e.target.value)
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setShowAlert(key ? true : false)
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Delivery Order")
    if (createAlert !== undefined) {
      setShowAlert(true)
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text })

      history.replace((createAlert = undefined))
    } else {
      return getData()
    }
    getData()
    return () => {
      setIsLoading(false)
      setIsSearching(false)
      setShowAlert(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.tgl_batas_waktu_mulai,
    dataFilter?.filter?.tgl_batas_waktu_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.gudang,
    dataFilter?.filter?.status_approval,
  ])

  const Table = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      if (convert === "VER")
        return {
          variant: "outline-success",
          label: "VERIFIED",
        };
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISION",
        };
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        };
      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
    }
    return (
      <>
        <b>List Data Delivery Order</b>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Delivery Order</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th width={200}>Customer</Th>
              <Th width={200}>Gudang</Th>
              <Th width={300}>Item Barang</Th>
              <ThFixed>Batas Waktu Pengiriman</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/delivery-order/detail-delivery-order/${val.id_delivery_order}`,
                          { ...location?.state, do: dataFilter }
                        )
                      }}
                    />
                    {(val.status_approval === "REV") && (
                      <UpdateButton
                        onClick={() => history.push(`/inventory/transaksi/delivery-order/edit-delivery-order/${val.id_delivery_order}`, {
                          ...location?.state, do: dataFilter,
                          data: {
                            catatan: val.catatan,
                            alamat_pengiriman: val.alamat_pengiriman
                          }
                        })}
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_delivery_order ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_delivery_order ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_sales_order ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_sales_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>
                  <ul className="pl-3">
                    {val.barang_jadi.map((item, index) => <li key={index}>{item.nama_barang ?? "-"}</li>)}
                  </ul>
                </Td>
                <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</TdFixed>
                <TdFixed>
                  <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchKey} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => { history.push("/inventory/transaksi/delivery-order/list-so", { ...location?.state, do: dataFilter })}}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? <DataStatus loading={true} text="Memuat data..." /> : data.length > 0 ? <Table /> : <DataStatus text="Tidak ada data" /> }

      {modalFilter === true && (
        <ModalFilterDO
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default DeliveryOrder
