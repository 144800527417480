import React, { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Card, Row, Col } from "react-bootstrap"
import Axios from "axios"
import {
  CRUDLayout,
  DataStatus,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
} from "components"
import { RupiahConvert } from "utilities"
import { ComplimentApi } from "api"
import { InfoCompliment } from "./section"

const ComplimentDetail = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const {id} = useParams()
  const tglCompliment = location?.state?.detail?.tgl_compliment
  
  const [readData, setReadData] = useState({})

  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setFetchingStatus({ loading: true, success: false })

    Axios.all([
      ComplimentApi.getSingle({ id_compliment: id, tanggal: tglCompliment }),
    ])
      .then(Axios.spread((compliment) => {
        setReadData(compliment?.data?.data)
        setFetchingStatus({ loading: false, success: true })
      }))
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({ loading: false, success: false })
      })
  }
  useEffect(() => {
    getInitialData()
    setNavbarTitle("Compliment")
  }, [])

  const total = readData?.detail?.reduce((acc, val) => Math.round(acc + parseFloat(val?.qty_compliment || 0) * parseFloat(val?.harga_satuan || 0)), 0)
  const CatatanApproval = () => {
    const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
      <>
        <small>{labelHeader}</small>
        <p>
          <b>{karyawanName}</b>
        </p>
        <small>{labelCatatan}</small>
        <p>
          <b>{catatanValue}</b>
        </p>
      </>
    );

    return (
      <>
        <div className="mt-4">
          <span style={{ fontSize: "14px" }}>
            <b>Catatan Approval Compliment</b>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row>
              {readData?.stakeholder === undefined ? (
                <Col className="text-center">
                  <small>Memuat Data...</small>
                </Col>
              ) : (
                readData?.stakeholder.map((val, index) => (
                  <>
                    <Col md={3} key={index}>
                      <InfoItem
                        labelHeader={val.status_approval === "APP"
                          ? "Pengesah"
                          : index === 0
                          // ? "Pembuat"
                          ? "Pengaju"
                          : `Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                        }
                        karyawanName={val.nama_karyawan ?? "-"}
                        labelCatatan={
                          val.status_approval === "APP"
                            ? "Catatan Pengesah"
                            : index !== 0
                            ? `Catatan Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : ""
                        }
                        catatanValue={index !== 0 ? val.catatan : ""}
                      />
                    </Col>
                  </>
                ))
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
  const ItemComplimentTable = () => (
    <>
      <div className="mb-1 mt-4" style={{ fontSize: "14px" }}><b>Item Compliment</b></div>
      <CRUDLayout.Table>
        <THead>
          <tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th>Item Barang</Th>
            <Th style={{ width: 200 }}>Gudang</Th>
            <Th style={{ width: 100 }}>Satuan</Th>
            <Th style={{ width: 100 }}>Qty. Compliment</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            <Th style={{ width: 150 }}>Sub Total</Th>
          </tr>
        </THead>
        <TBody>
          {readData?.detail?.map((item, index) => {
            const sub_total = Math.round(parseFloat(item?.qty_compliment || 0) * parseFloat(item.harga_satuan || 0))
            return (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{item.kode_item ?? "-"}</TdFixed>
                <Td>{item.nama_item ?? "-"}</Td>
                <Td>{item.nama_gudang ?? "-"}</Td>
                <Td>{item.nama_satuan ?? "-"}</Td>
                <Td><div className="text-right">{item.qty_compliment ? parseFloat(item.qty_compliment) : 0}</div></Td>
                <Td><div className="text-right">{item.harga_satuan ? RupiahConvert(parseInt(item.harga_satuan).toString()).getWithComa : "Rp. 0"}</div></Td>
                <Td><div className="text-right">{sub_total ? RupiahConvert(parseInt(sub_total).toString()).getWithComa : "Rp. 0"}</div></Td>
              </Tr>
            )
          })}
          <Tr>
            <Td colSpan={7} textRight className="align-middle py-2 font-weight-bold text-right">Total</Td>
            <Td textRight className="align-middle py-2 font-weight-bold text-right">{total ? RupiahConvert(String(parseInt(total))).detail : "Rp. 0"}</Td>
          </Tr>
        </TBody>
      </CRUDLayout.Table>
    </>
  )


  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/inventory/transaksi/compliment", { ...location?.state })}/>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {fetchingStatus?.loading
        ? <DataStatus loading text="Memuat . . ." />
        : fetchingStatus?.success
        ? <>
            <div className="font-weight-bold mb-2">Detail Data Compliment</div>
            <Alert
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              showCloseButton={true}
              onClose={() => setAlertConfig({ ...alertConfig, show: false })}
            />
            <InfoCompliment data={readData} />
            <ItemComplimentTable />
            <CatatanApproval />
          </>
        : <DataStatus text="Data gagal dimuat" />
      }
    </CRUDLayout>
  )
}

export default ComplimentDetail