// React
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

// Component
import { Row, Col } from "react-bootstrap"
import {
  CRUDLayout, Alert, DataStatus, InputSearch, 
  Pagination, Table, Th, Td, ThFixed, TdFixed,
  ReadButton, CreateButton
} from "components"
import { DateConvert, TableNumber } from "utilities"

// API
import { PenerimaanSuratJalanApi } from "api"

const PenerimaanSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataPenerimaanSuratJalan, setDataPenerimaanSuratJalan] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PenerimaanSuratJalanApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPenerimaanSuratJalan(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    setNavbarTitle("Penerimaan Surat Jalan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength])

  const PageContent = () => {
    const DataTable = () => {
      return (
        <>
          <b className="mb-2" style={{ fontSize: "14px" }}>List Data Penerimaan Surat Jalan</b>
          <Table>
            <thead>
              <tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Tgl. Penerimaan Surat Jalan</ThFixed>
                <ThFixed>No. Penerimaan Surat Jalan</ThFixed>
                <ThFixed>No. Surat Jalan</ThFixed>
                <Th>Gudang</Th>
                <Th>Petugas Pengiriman</Th>
                <Th>Armada Pengiriman</Th>
              </tr>
            </thead>
            <tbody>
              {dataPenerimaanSuratJalan.map((val, index) => (
                <tr key={index}>
                  <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
                  <TdFixed className="align-middle">
                    <ReadButton onClick={() => history.push('/inventory/transaksi/penerimaan-surat-jalan/detail/'+ val.id_surat_jalan, { infoValue: val })} />
                  </TdFixed>
                  <TdFixed>{val.tgl_penerimaan_surat_jalan ? DateConvert(new Date(val.tgl_penerimaan_surat_jalan)).defaultDMY: "-"}</TdFixed>
                  <TdFixed>{val.no_penerimaan_surat_jalan ?? "-"}</TdFixed>
                  <TdFixed>{val.no_surat_jalan ?? "-"}</TdFixed>
                  <Td>{val.nama_gudang ?? "-"}</Td>
                  <Td>{val.nama_sopir ?? "-"}</Td>
                  <Td>{val.nama_item_aset ?? "-"}</Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )
    }

    if (!dataPenerimaanSuratJalan || dataPenerimaanSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push('/inventory/transaksi/penerimaan-surat-jalan/list-surat-jalan', { ...location?.state })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  )
}

export default PenerimaanSuratJalan
