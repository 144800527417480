// React
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

// API
import Axios from "axios"
import { DeliveryOrderApi } from "../../../../api"

// Component
import {
  CRUDLayout, InputSearch, Alert, Pagination, THead,
  TBody, Tr, ThFixed, TdFixed, Th, Td, ActionButton,
  BackButton, DataStatus, FilterButton
} from "../../../../components"
import { DateConvert, PageNumber, TableNumber } from "../../../../utilities"

// Icon
import { IoAddOutline } from 'react-icons/io5'
import { ModalFilterSO } from "./comps"

const ListSo = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")
  const [data, setData] = useState([])
  const history = useHistory()
  const location = useLocation()
  const [showAlert, setShowAlert] = useState(false)
  const [modalFilter, setModalFilter] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.so?.filter?.active,
      tgl_sales_order_mulai: location?.state?.so?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: location?.state?.so?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: location?.state?.so?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: location?.state?.so?.filter?.tgl_batas_waktu_selesai,
      customer: location?.state?.so?.filter?.customer,
      gudang: location?.state?.so?.filter?.gudang,
    },
    pagination: {
      page: location?.state?.so?.filter?.page ?? "1",
      dataLength: location?.state?.so?.filter?.dataLength ?? "10",
      totalPage: location?.state?.so?.filter?.totalPage ?? "1",
      dataCount: location?.state?.so?.filter?.dataCount ?? "0",
    }
  })

  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    Axios.all([DeliveryOrderApi.getSales({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: dataFilter?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: dataFilter?.filter?.tgl_batas_waktu_selesai,
      customer: dataFilter?.filter?.customer,
      gudang: dataFilter?.filter?.gudang,
    })])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? "-")
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            }
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        })
        setShowAlert(true)
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        } else {
          setShowAlert(false)
        }
        setIsLoading(false)
      })
  }
// SEARCH HANDLER
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchKey(e.target.value)
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setShowAlert(key ? true : false)
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };
  useEffect(() => {
    setNavbarTitle("Delivery Order")

    getData()
    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle, 
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.tgl_batas_waktu_mulai,
    dataFilter?.filter?.tgl_batas_waktu_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.gudang,
  ])

  const Table = () => {
    return (
      <>
        <b>List Sales Order Yang Siap Dijadikan Delivery Order</b>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th width={250}>Customer</Th>
              <Th width={200}>Gudang</Th>
              <Th>Item Barang</Th>
              <ThFixed>Batas Waktu Pengiriman</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_sales_order ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_sales_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>
                  <ul className="pl-3">
                    {val.barang_jadi.map((item, index) => <li key={index}>{item.nama_barang ?? "-"}</li>)}
                  </ul>
                </Td>
                <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text={<IoAddOutline />}
                      size="sm"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => history.push(`/inventory/transaksi/delivery-order/tambah-delivery-order/${val.id_sales_order}/${val.id_gudang}`, {
                        ...location?.state, so: dataFilter,
                        data: {
                          nama_gudang: val.nama_gudang,
                          alamat_pengiriman: val.alamat_pengiriman
                        }
                      })}
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchKey} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.push("/inventory/transaksi/delivery-order", { ...location?.state, so: dataFilter })
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter === true && (
        <ModalFilterSO
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListSo
