import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { SuratJalanApi } from "api"

const ModalFilterDO = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataCustomer, setDataCustomer] = useState([{ label: "Semua", value: undefined }]);
  const [dataGudang, setDataGudang] = useState([{ label: "Semua", value: undefined }]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      SuratJalanApi.getDropdownCustomer(),
      SuratJalanApi.getDropdownGudang(),
    ])
      .then(
        Axios.spread((customer, gudang) => {
          const mapDataCustomer = customer?.data?.data ? customer.data.data.map((val) => ({ label: val?.nama_customer, value: val?.id_customer })) : [];
          const mapDataGudang = gudang?.data?.data ? gudang.data.data.map((val) => ({ label: val?.nama_gudang, value: val?.id_gudang })) : [];

          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
          setDataGudang([...dataGudang, ...mapDataGudang]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_delivery_order_mulai: data?.filter?.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: data?.filter?.tgl_delivery_order_selesai,
    tgl_batas_waktu_mulai: data?.filter?.tgl_batas_waktu_mulai,
    tgl_batas_waktu_selesai: data?.filter?.tgl_batas_waktu_selesai,
    customer: data?.filter?.customer,
    gudang: data?.filter?.gudang,
  };
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalDO = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_delivery_order_mulai: startDate,
      tgl_delivery_order_selesai: endDate,
    });
  };
  const onTanggalBW = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_batas_waktu_mulai: startDate,
      tgl_batas_waktu_selesai: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_delivery_order_mulai: undefined,
      tgl_delivery_order_selesai: undefined,
      tgl_batas_waktu_mulai: undefined,
      tgl_batas_waktu_selesai: undefined,
      customer: undefined,
      gudang: undefined,
    });
  };

  return (
    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Delivery Order"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal delivery order"
            startDate={values.tgl_delivery_order_mulai ? new Date(values.tgl_delivery_order_mulai) : ""}
            endDate={values.tgl_delivery_order_selesai ? new Date(values.tgl_delivery_order_selesai) : ""}
            onChange={(dates) => onTanggalDO(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Batas Waktu"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal batas waktu"
            startDate={values.tgl_batas_waktu_mulai ? new Date(values.tgl_batas_waktu_mulai) : ""}
            endDate={values.tgl_batas_waktu_selesai ? new Date(values.tgl_batas_waktu_selesai) : ""}
            onChange={(dates) => onTanggalBW(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dataCustomer.find((val) => val.value === values.customer)}
            option={dataCustomer}
            onChange={(val) => setFieldValue("customer", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.gudang}
            label="Gudang"
            placeholder="Pilih gudang"
            defaultValue={dataGudang.find((val) => val.value === values.gudang)}
            option={dataGudang}
            onChange={(val) => setFieldValue("gudang", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilterDO;
