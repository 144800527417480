import React from 'react'
import { Card, Row, Col } from "react-bootstrap"
import { InfoItemHorizontal } from "components"
import { DateConvert } from 'utilities'

const InfoCompliment = ({ data }) => {
  return (
    <Card>
        <Card.Body>
            <Row>
                <Col>
                    <InfoItemHorizontal width={200} label="Tgl. Compliment" text={data?.tgl_compliment ? DateConvert(new Date(data?.tgl_compliment)).detail : "-"} />
                    <InfoItemHorizontal width={200} label="No. Compliment" text={data?.no_compliment ?? "-"} />
                    <InfoItemHorizontal width={200} label="Customer" text={data?.nama_customer ?? "-"} />
                    <InfoItemHorizontal width={200} label="Petugas Pemberi Compliment" text={data?.nama_petugas ?? "-"} />
                </Col>
                <Col>
                    <InfoItemHorizontal width={200} label="Keterangan" text={data?.keterangan ?? "-"} />
                </Col>
            </Row>
        </Card.Body>
    </Card>
  )
}

export default InfoCompliment