import React, { useState, useEffect, useRef } from 'react'
import { Spinner, DropdownButton, Dropdown } from 'react-bootstrap'
import { useReactToPrint } from "react-to-print"
import _ from 'lodash'
import Axios from 'axios'
import {
    CRUDLayout,
    Alert,
    DataStatus,
    ActionButton,
    Table,
    Th,
    Td,
    DatePicker,
    SelectSearch,
    FilterButton
} from 'components'
import { DateConvert } from 'utilities'
import { LaporanStockUpdateApi } from 'api'
import { ModalFilter } from './Section'
import {
    PrintStokTerbaru,
    TableStokTerbaru
} from "./components"
// import FileSaver from "file-saver"
import { saveAs } from 'file-saver'

const LaporanStockUpdate = ({ setNavbarTitle }) => {
// PRINT SECTION
    const refPrint = useRef()
    const reactToPrint = useReactToPrint({ content: () => refPrint.current })
// STATE DATA
    const [data, setData] = useState([])                       
// fILTER SECTION
    const [isFetchingReport, setIsFetchingReport] = useState(false) 
    const [showModalFilter, setShowModalFilter] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    const [dataFilter, setDataFilter] = useState({
        filter: {
            active: false,
            tanggal: undefined,
            id_kelompok: undefined,
            id_jenis: undefined,
            id_item_buaso: undefined,
            id_gudang: "0"
        }
    })
    const [dropdown, setDropdown] = useState({
        item_buaso: [],
        kelompok: [],
        jenis: [],
        gudang: [{ value: "0", kode_gudang: "0", label: "Semua Gudang" }],
    })
    const [alertConfig, setAlertConfig] = useState({
        show: false,
        variant: 'primary',
        text: ''
    })

// FETCH DROPDOWN KELOMPOK DAN GUDANG
    const fetchDropdown = () => {
        Axios.all([
        LaporanStockUpdateApi.getDropdown({ tipe: "kelompok" }),
        LaporanStockUpdateApi.getDropdown({ tipe: "gudang" }),
        ])
        .then(
            Axios.spread((kelompok, gudang) => {
            // Tambah item dropdown gudang untuk semua gudang
            const mapGudang = dropdown.gudang.concat(gudang?.data?.data?.map(item => ({ ...item, value: item.id_gudang, label: item.nama_gudang })))
            const mapKelompok = kelompok?.data?.data?.map(item => ({ ...item, value: item.id_kelompok, label: item.nama_kelompok }))

            setDropdown(prev => ({ ...prev, kelompok: mapKelompok ?? [], gudang: mapGudang ?? [] }))
            })
        )
        .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Data gagal dimuat!" }))
    }
// FETCH DATA REPORT SESUAI FILTER
    const getReportData = () => {
        setIsFetchingReport(true)

        LaporanStockUpdateApi.getReport({
        ...dataFilter.filter,
        tanggal: DateConvert(new Date(dataFilter.filter.tanggal)).default,
        })
        .then(({ data }) => setData(data?.data ?? []))
        .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Data gagal dimuat!" }))
        .finally(() => setIsFetchingReport(false))
    }
    useEffect(() => {
        setNavbarTitle("Stok Terbaru")
        fetchDropdown()
    
        return () => {
        setDropdown({ item_buaso: [], gudang: [] })
        setData([])
        }
    }, [setNavbarTitle])
// FETCH REPORT ON DATA FILTER CHANGE
    useEffect(() => {
        if (dataFilter.filter.active) getReportData()
    }, [dataFilter])
// EXPORT DATA
    const exportData = tipe => {
        setLoadingExport(true)

        LaporanStockUpdateApi.export({
            tipe,
            id_item_buaso: dataFilter.filter.id_item_buaso,
            id_gudang: dataFilter.filter.id_gudang,
            tanggal: DateConvert(new Date(dataFilter.filter.tanggal)).default,
        })
        .then(res => {
            // const data = res.data.data_only
            // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data
            // FileSaver.saveAs(urlFile, data)
            const link = res?.data?.data
            const parse = 'https://' + link.split('/').filter((_val, idx) => idx !== 0).filter((_val, idx) => idx !== 0).join('/')
            saveAs(parse, "PurchaseRequest")
        }).catch(() => {
            setAlertConfig({
            show: true,
            variant: "danger",
            text: "Export Data gagal",
            })
        }).finally(() => setLoadingExport(false))
    }


// DATA TABLE
    const PageContent = () => {
        const DataTable = () => (
            <>
                <span style={{fontSize:"14px"}}><b>List Data Stok Terbaru</b></span>
                <Table>
                    <thead>
                        <tr>
                            <Th width="12px">No</Th>
                            <Th width="12px">Kode Item</Th>
                            <Th>Item Bahan</Th>
                            <Th width="200px">Kelompok Bahan</Th>
                            <Th width="150px">Jenis Bahan</Th>
                            <Th width="250px">Gudang</Th>
                            <Th width="100px">Qty.</Th>
                            <Th width="80px">Satuan</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {!data || data.length > 0 ?
                            data.map((val, index) => (
                                <tr key={index}>
                                    <Td className="align-middle text-nowrap text-center">{index + 1}</Td>
                                    <Td className="align-middle text-nowrap text-center">{val?.kode_bahan ?? '-'}</Td>
                                    <Td className="align-middle text-nowrap ">{val?.nama_bahan ?? '-'}</Td>
                                    <Td className="align-middle text-capitalize text-nowrap">{val?.kelompok_bahan ?? '-'}</Td>
                                    <Td className="align-middle text-nowrap">{val?.jenis_bahan ?? '-'}</Td>
                                    <Td className="align-middle">{val?.nama_gudang ?? '-'}</Td>
                                    <Td className="align-middle text-uppercase text-right text-nowrap">{val?.qty ?? '0'}</Td>
                                    <Td className="align-middle text-uppercase text-nowrap">{val?.satuan_pakai ?? '-'}</Td>
                                </tr>
                            ))
                            : <tr><td colSpan={8} className="text-center">Tidak ada data</td></tr>
                        }
                    </tbody>
                </Table>
            </>
        )
        return <DataTable />
    }

    return (
        <CRUDLayout>
            {/* head section */}
            <CRUDLayout.Head>
                <div className="d-flex mb-2 ml-2">
                    <FilterButton 
                        active={dataFilter?.filter?.active}
                        onClick={() => setShowModalFilter(true)}
                    />
                    <DropdownButton
                        className='ml-3'
                        variant='warning text-white'
                        disabled={loadingExport ? true : false}
                        title={loadingExport
                        ? <>
                            <Spinner animation="border" size="sm" />
                            <span className="ml-2 mr-2  ">Loading...</span>
                          </>
                        : <span className="text-white mr-2">EXPORT DATA</span>
                        }
                        >

                        <Dropdown.Item onClick={() => exportData("excel")}>Excel (.xlsx)</Dropdown.Item>
                        <Dropdown.Item onClick={() => exportData("pdf")}>PDF (.pdf)
                    </Dropdown.Item>
                    </DropdownButton>
                </div>
            </CRUDLayout.Head>
            {/* alert */}
            <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => setAlertConfig({
                    ...alertConfig,
                    show: false
                })}
            />
            {/* content */}
            {isFetchingReport
                ? <DataStatus loading={true} text="Memuat data . . ." />
                : <div style={{ minHeight: '45vh' }}><PageContent /></div>
            }
            {/* Modal Filter */}
            { showModalFilter && <ModalFilter
                show={showModalFilter}
                setShow={setShowModalFilter}
                dropdown={dropdown}
                setDropdown={setDropdown}
                data={dataFilter}
                setData={setDataFilter}
                />
            }
            {/* PDF Laporan Stock Terbaru */}
            <div style={{ display: "none" }}>
                <PrintStokTerbaru ref={refPrint} dataFilter={dataFilter.filter} dataTable={data} />
            </div>
        </CRUDLayout>
    )
}

export default LaporanStockUpdate

// {/* <Card className='mb-3'>
// <Card.Header>
//     <strong>Pencarian Data</strong>
// </Card.Header>
// <Card.Body>
//     {isPageLoading
//         ? <DataStatus text="Memuat Data ..." loading={isPageLoading} />
//         : <Row>
//             <Col lg="2">
//                 <DatePicker
//                     label="Tanggal"
//                     type="date"
//                     placeholderText="dd/mm/yyyy"
//                     selected={searchFilter.tanggal}
//                     onChange={(selectedDate) => setSearchFilter({ ...searchFilter, tanggal: selectedDate })}
//                 />
//             </Col>
//             <Col lg="2">
//                 <SelectSearch
//                     label="Kelompok"
//                     placeholder="Pilih Kelompok"
//                     option={dropdown.kelompok.map((val) => {
//                         return {
//                             value: val.id_kelompok,
//                             label: val.nama_kelompok,
//                         };
//                     })}
//                     onChange={(val) => {
//                         setSearchFilter({ 
//                             ...searchFilter, 
//                             id_kelompok: val.value,
//                             id_jenis: "",
//                             id_item_buaso: "",
//                         })

//                         getJenisData(val.value)
//                     }}
//                 />
//             </Col>
//             <Col lg="2">
//                 <SelectSearch
//                     label="Jenis"
//                     placeholder="Pilih Jenis"
//                     option={dropdown.jenis.map((val) => {
//                         return {
//                             value: val.id_jenis,
//                             label: val.nama_jenis,
//                         };
//                     })}
//                     onChange={(val) => {
//                         setSearchFilter({ 
//                             ...searchFilter, 
//                             id_jenis: val.value,
//                             id_item_buaso: "",
//                         })

//                         getItemData(val.value)
//                     }}
//                     isDisabled={searchFilter.id_kelompok === "" ? true : false}
//                     loading={loadingDropdown.jenis}
//                 />
//             </Col>
//             <Col lg="3">
//                 <SelectSearch
//                     label="Item Bahan"
//                     placeholder="Pilih bahan"
//                     option={dropdown.item_buaso.map((val) => {
//                         return {
//                             value: val.id_item_buaso,
//                             label: val.nama_item,
//                         };
//                     })}
//                     onChange={(val) => setSearchFilter({ ...searchFilter, id_item_buaso: val.value })}
//                     isDisabled={searchFilter.id_jenis === "" ? true : false}
//                     loading={loadingDropdown.item_buaso}
//                 />
//             </Col>
//             <Col lg="2">
//                 <SelectSearch
//                     label="Gudang"
//                     placeholder="Pilih gudang"
//                     option={dropdown.gudang.map((val) => {
//                         return {
//                             value: val.id_gudang,
//                             label: val.nama_gudang,
//                         };
//                     })}
//                     onChange={(val) => setSearchFilter({ ...searchFilter, id_gudang: val.value })}
//                 />
//             </Col>
//             <Col lg="1" className="d-flex align-items-center pt-3">
//                 <ActionButton
//                     text="Cari"
//                     size="sm"
//                     onClick={() => {
//                         if (searchFilter.id_item_buaso === '' || searchFilter.id_gudang === '') {
//                             setAlertConfig({
//                                 show: true,
//                                 variant: 'danger',
//                                 text: 'Pilih Item Bahan dan Gudang'
//                             })
//                         }
//                         else { getReportData() }
//                     }}
//                 />
//             </Col>
//         </Row>
//     }
// </Card.Body>
// </Card> */}

// FETCH DROPDOWN ITEM BAHAN DAN GUDANG
// const getInitialData = () => {
//     setIsPageLoading(true)
//     Axios.all([
//         LaporanStockUpdateApi.getDropdown({ tipe: 'kelompok' }),
//         LaporanStockUpdateApi.getDropdown({ tipe: 'gudang' })
//     ])
//         .then(
//             Axios.spread((item_buaso, gudang) => {
//                 // Tambah item dropdown gudang untuk semua gudang
//                 if (Array.isArray(gudang.data.data)) {
//                     gudang.data.data.unshift({
//                         id_gudang: "0",
//                         kode_gudang: "0",
//                         nama_gudang: "Semua Gudang"
//                     })
//                 }
//                 setDropdown({
//                     ...dropdown,
//                     kelompok: item_buaso?.data?.data ?? [],
//                     gudang: gudang?.data?.data ?? [],
//                 });
//             })
//         )
//         .catch((err) => setAlertConfig({
//             show: true,
//             variant: 'danger',
//             text: 'Data gagal dimuat!'
//         }))
//         .finally(() => setIsPageLoading(false));
// }
// const getJenisData = value => {
//     setLoadingDropdown({
//         jenis: true,
//         item_buaso: true
//     })

//     LaporanStockUpdateApi.getDropdown({ 
//         tipe: 'jenis',
//         id_kelompok: value
//     })
//         .then(res => {
//             setDropdown({
//                 ...dropdown,
//                 jenis: res.data.data,
//             });
//         })
//         .catch(() => {
//             setDropdown({
//                 ...dropdown,
//                 jenis: [],
//             });
//         })
//         .finally(() => setLoadingDropdown({
//             jenis: false,
//             item_buaso: false
//         }));
// }
// const getItemData = value => {
//     setLoadingDropdown({
//         ...loadingDropdown,
//         item_buaso: true
//     })

//     LaporanStockUpdateApi.getDropdown({ 
//         tipe: 'item_buaso',
//         id_jenis: value
//     })
//         .then(res => {
//             setDropdown({
//                 ...dropdown,
//                 item_buaso: res.data.data,
//             });
//         })
//         .catch(() => {
//             setDropdown({
//                 ...dropdown,
//                 item_buaso: [],
//             });
//         })
//         .finally(() => setLoadingDropdown({
//             ...loadingDropdown,
//             item_buaso: false
//         }));
// }

// const [isPageLoading, setIsPageLoading] = useState(false)
// const [loadingDropdown, setLoadingDropdown] = useState({ 
//     jenis: false,
//     item_buaso: false,
// })

// const [searchFilter, setSearchFilter] = useState({
//     active: false,
//     tanggal: new Date(),
//     id_item_buaso: '',
//     id_kelompok: '',
//     id_jenis: '',
//     id_gudang: ''
// })

{/* <Table>
<thead>
    <tr>
        <Th width="12px">No</Th>
        <Th width="12px">Kode Item</Th>
        <Th>Item Bahan</Th>
        <Th width="100px">Kelompok Bahan</Th>
        <Th width="100px">Jenis Bahan</Th>
        <Th width="200px">Gudang</Th>
        <Th width="50px">Qty.</Th>
        <Th width="50px">Satuan</Th>
    </tr>
</thead>
<tbody>
    {!data || data.length > 0 ?
        data.map((val, index) => (
            <tr key={index}>
                <Td className="align-middle text-nowrap text-center">{index + 1}</Td>
                <Td className="align-middle text-nowrap text-center">{val?.kode_bahan ?? '-'}</Td>
                <Td className="align-middle text-nowrap ">{val?.nama_bahan ?? '-'}</Td>
                <Td className="align-middle text-capitalize text-nowrap">{val?.kelompok_bahan ?? '-'}</Td>
                <Td className="align-middle text-nowrap">{val?.jenis_bahan ?? '-'}</Td>
                <Td className="align-middle">{val?.nama_gudang ?? '-'}</Td>
                <Td className="align-middle text-uppercase text-right text-nowrap">{val?.qty ?? '0'}</Td>
                <Td className="align-middle text-uppercase text-nowrap">{val?.satuan_pakai ?? '-'}</Td>
            </tr>
        ))
        : <tr><td colSpan={8} className="text-center">Tidak ada data</td></tr>
    }
</tbody>
</Table> */}

// Jika ada data, export ke pdf
// if (data.length > 0) reactToPrint()
