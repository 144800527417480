import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { SuratJalanApi } from "api"

const ModalFilter = ({ show, setShow, data, setData, SESSION_KEY }) => {
  // STATE DATA
  const [dataPetugasPengirim, setDataPetugasPengirim] = useState([{ label: "Semua", value: undefined }]);
  const [dataArmadaPengirim, setDataArmadaPengirim] = useState([{ label: "Semua", value: undefined }]);
  const [dataItem, setDataItem] = useState([{ label: "Semua", value: undefined }]);
  const [dataJenisSJ, setDataJenisSJ] = useState([
    { label: "Semua", value: undefined },
    { label: "Konsinyasi", value: true },
    { label: "Penjualan", value: false },
  ]);
  const [dataGudang, setDataGudang] = useState([{ label: "Semua", value: undefined }]);
  const [dataStatusApproval, setDataStatusApproval] = useState([
    {
      label: "Semua",
      value: undefined,
    },
    {
      label: "APPROVED",
      value: "APP",
    },
    {
      label: "VERIFIED",
      value: "VER",
    },
    {
      label: "REVISION",
      value: "REV",
    },
    {
      label: "REJECTED",
      value: "REJ",
    },
    {
      label: "PENDING",
      value: "PEN",
    },
  ]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      SuratJalanApi.getDropdownPetugas(),
      SuratJalanApi.getDropdownArmada(),
      SuratJalanApi.getGudang(),
      SuratJalanApi.getDropdownItem(),
    ])
      .then(
        Axios.spread((petugas, armada, gudang, item) => {
          const mapDataPetugas = petugas?.data?.data ? petugas.data.data.map((val) => ({ label: val?.nama_supir, value: val?.id_supir })) : [];
          const mapDataArmada = armada?.data?.data ? armada.data.data.map((val) => ({ label: val?.nama_kendaraan, value: val?.id_kendaraan })) : [];
          const mapDataGudang = gudang?.data?.data ? gudang.data.data.map((val) => ({ label: val?.nama_gudang, value: val?.id_gudang })) : [];
          const mapDataItem = item?.data?.data ? item.data.data.map((val) => ({ label: val?.nama_item, value: val?.id_item_buaso })) : [];

          setDataPetugasPengirim([...dataPetugasPengirim, ...mapDataPetugas]);
          setDataGudang([...dataGudang, ...mapDataGudang]);
          setDataArmadaPengirim([...dataArmadaPengirim, ...mapDataArmada]);
          setDataItem([...dataItem, ...mapDataItem]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_surat_jalan_mulai: data?.filter?.tgl_surat_jalan_mulai,
    tgl_surat_jalan_selesai: data?.filter?.tgl_surat_jalan_selesai,
    tgl_delivery_order_mulai: data?.filter?.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: data?.filter?.tgl_delivery_order_selesai,
    tgl_batas_waktu_mulai: data?.filter?.tgl_batas_waktu_mulai,
    tgl_batas_waktu_selesai: data?.filter?.tgl_batas_waktu_selesai,
    petugas_pengirim: data?.filter?.petugas_pengirim,
    armada_pengirim: data?.filter?.armada_pengirim,
    gudang: data?.filter?.gudang,
    item_barang: data?.filter?.item_barang,
    jenis_sj: data?.filter?.jenis_sj,
    status_approval: data?.filter?.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalSJ = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_surat_jalan_mulai: startDate,
      tgl_surat_jalan_selesai: endDate,
    });
  };
  const onTanggalDO = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_delivery_order_mulai: startDate,
      tgl_delivery_order_selesai: endDate,
    });
  };
  const onTanggalBW = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_batas_waktu_mulai: startDate,
      tgl_batas_waktu_selesai: endDate,
    });
  };
  // const onResetButtonClick = (values, setValues) => {
  //   setValues({
  //     tgl_surat_jalan_mulai: undefined,
  //     tgl_surat_jalan_selesai: undefined,
  //     tgl_delivery_order_mulai: undefined,
  //     tgl_delivery_order_selesai: undefined,
  //     tgl_batas_waktu_mulai: undefined,
  //     tgl_batas_waktu_selesai: undefined,
  //     petugas_pengirim: undefined,
  //     armada_pengirim: undefined,
  //     gudang: undefined,
  //     status_approval: undefined,
  //   });
  // };
  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER)
    sessionStorage.removeItem(SESSION_KEY.PAGINATION)

    setData(prev => ({
      ...prev,
      filter: {
        tgl_surat_jalan_mulai: undefined,
        tgl_surat_jalan_selesai: undefined,
        tgl_delivery_order_mulai: undefined,
        tgl_delivery_order_selesai: undefined,
        tgl_batas_waktu_mulai: undefined,
        tgl_batas_waktu_selesai: undefined,
        petugas_pengirim: undefined,
        armada_pengirim: undefined,
        gudang: undefined,
        status_approval: undefined,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
        per_page: 10,
      }
    }))
    setShow(false)
  }


  return (
    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Surat Jalan"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal surat jalan"
            startDate={values.tgl_surat_jalan_mulai ? new Date(values.tgl_surat_jalan_mulai) : ""}
            endDate={values.tgl_surat_jalan_selesai ? new Date(values.tgl_surat_jalan_selesai) : ""}
            onChange={(dates) => onTanggalSJ(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Delivery Order"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal delivery order"
            startDate={values.tgl_delivery_order_mulai ? new Date(values.tgl_delivery_order_mulai) : ""}
            endDate={values.tgl_delivery_order_selesai ? new Date(values.tgl_delivery_order_selesai) : ""}
            onChange={(dates) => onTanggalDO(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Batas Waktu"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal batas waktu"
            startDate={values.tgl_batas_waktu_mulai ? new Date(values.tgl_batas_waktu_mulai) : ""}
            endDate={values.tgl_batas_waktu_selesai ? new Date(values.tgl_batas_waktu_selesai) : ""}
            onChange={(dates) => onTanggalBW(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.petugas_pengirim}
            label="Petugas Pengiriman"
            placeholder="Pilih petugas pengiriman"
            defaultValue={dataPetugasPengirim.find((val) => val.value === values.petugas_pengirim)}
            option={dataPetugasPengirim}
            onChange={(val) => setFieldValue("petugas_pengirim", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.armada_pengirim}
            label="Armada Pengiriman"
            placeholder="Pilih armada pengiriman"
            defaultValue={dataArmadaPengirim.find((val) => val.value === values.armada_pengirim)}
            option={dataArmadaPengirim}
            onChange={(val) => setFieldValue("armada_pengirim", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.item_barang}
            label="Item Barang"
            placeholder="Pilih item barang"
            defaultValue={dataItem.find((val) => val.value === values.item_barang)}
            option={dataItem}
            onChange={(val) => setFieldValue("item_barang", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.gudang}
            label="Gudang Asal"
            placeholder="Pilih gudang asal"
            defaultValue={dataGudang.find((val) => val.value === values.gudang)}
            option={dataGudang}
            onChange={(val) => setFieldValue("gudang", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.jenis_sj}
            label="Jenis Surat Jalan"
            placeholder="Pilih jenis surat jalan"
            defaultValue={dataJenisSJ.find((val) => val.value === values.jenis_sj)}
            option={dataJenisSJ}
            onChange={(val) => setFieldValue("jenis_sj", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={dataStatusApproval.find((val) => val.value === values.status_approval)}
            option={dataStatusApproval}
            onChange={(val) => setFieldValue("status_approval", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
