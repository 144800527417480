import Services from "../../../services";

class ComplimentApi {
  getPage(params) {
    return Services.get("/compliment/page", { params });
  }
  getSingle(params) {
    return Services.get('/compliment/single', { params });
  }
  getNomor(params) {
    return Services.get("/compliment/no_baru", { params });
  }
  getStokBarang(params) {
    return Services.get("/compliment/item_barang", { params });
  }
  create(data) {
    return Services.post("/compliment", data);
  }
  update(data) {
    return Services.put("/compliment", data);
  }
  getDropdownCustomer() {
    return Services.get("/dropdown/customer");
  }
  getDropdownKaryawan() {
    return Services.get("/dropdown/karyawan");
  }
  getDropdownKelompok() {
    return Services.get("/dropdown/kelompok");
  }
  getDropdownJenis() {
    return Services.get("/dropdown/jenis");
  }
  getDropdownBarang(params) {
    return Services.get("/dropdown/item_barang", { params });
  }
}

export default new ComplimentApi();
