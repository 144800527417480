import Services from "../../../services";

class PenerimaanSuratJalanApi {
    getPage(params) {
        return Services.get('penerimaan_surat_jalan/page', { params })
    }

    // UNTUK GET SINGLE PENERIMAAN SJ, MENGGUNAKAN API GET DETAIL SJ
    getSingle(params) {
        return Services.get('surat_jalan/single', { params })
    }

    // UNTUK GET NOMOR PENERIMAAN / PEMBATALAN SURAT JALAN
    getNomor(params) {
        return Services.get(`penerimaan_surat_jalan/no_baru_penerimaan`, { params })
    }

    // UNTUK POST PENERIMAAN / PEMBATALAN SURAT JALAN
    // save(data) {
    //     return Services.post('penerimaan_surat_jalan/penerimaan', data)
    // }

    save(type, data) {
        const endpoint = type === 'pembatalan' ? 'pembatalan' : 'penerimaan'
        return Services.post(`penerimaan_surat_jalan/${endpoint}`, data)
    }

    list_sj(params) {
        return Services.get('penerimaan_surat_jalan/list_sj', { params })
    }

    newSJ(params) {
        return Services.get('penerimaan_surat_jalan/page', { params })
    }
}

export default new PenerimaanSuratJalanApi();
