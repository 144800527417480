import React, { forwardRef, useEffect } from "react"
import Logo from "config"
import { DateConvert } from "utilities"
import { TableStokTerbaru } from "."

const PrintStokTerbaru = forwardRef(({ dataFilter, dataTable }, ref) => {
  const KopSurat = () => {
    return (
      <div className="d-flex justify-content-start">
        <div>
          <img src={Logo.LOGO} width={110} />
        </div>
        <div style={{ marginLeft: 30, textAlign: "left" }}>
          <p>
            <b>CV KRISNA ADVENTURE</b><br />
            Jl. taman begawan nomor 123, Bali, Indonesia<br />
            Telp. : +62 819-0416-8666<br />
            Email : krisnaadventure@gmail.com<br />
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSurat />

      <div className="text-center h5 font-weight-bold mb-0">
        <u>Laporan Stok Terbaru</u>
      </div>
      <div className="text-center mb-2">
        {dataFilter?.tanggal && <span>{DateConvert(new Date(dataFilter?.tanggal)).defaultDMY}</span>}
      </div>

      <TableStokTerbaru data={dataTable} />
    </div >
  )
})

export default PrintStokTerbaru
