import React, {
  useState
} from 'react'
import {
  IoAddOutline
} from 'react-icons/io5'
import { AiOutlineCheck } from "react-icons/ai"
import {
  Table,
  THead,
  TBody,
  Tr,
  Td,
  Th,
  DataStatus,
  ActionButton,
} from 'components'
import {
  ModalTransfer
} from '../Comp'

export const TablePermintaan = ({ action, data, dataHistory, refresh, alertConfig, setAlertConfig }) => {
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })

  return (
    <>
      <div className="pb-2">
        <b>List Data Permintaan Produksi</b>
      </div>
      <Table>
        <THead className="bg-light">
          <Tr>
            <Th noPadding style={{ width: 40 }}>No</Th>
            <Th noPadding style={{ width: 150 }}>Kode Item</Th>
            <Th noPadding style={{ minWidth: 150 }}>Item Bahan</Th>
            <Th noPadding style={{ width: 100 }}>Satuan</Th>
            <Th noPadding style={{ width: 150 }}>Qty. Permintaan Produksi</Th>
            <Th noPadding style={{ width: 150 }}>Total Qty. Telah Diransfer</Th>
            <Th noPadding style={{ width: 40, display: action === 'VIEW' ? 'none' : '' }}>Aksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0
            ? data.map((val, index) => (
              <Tr key={index}>
                <Td className="text-center">
                  {index + 1}
                </Td>
                <Td>{val?.kode_item ?? '-'}</Td>
                <Td>{val?.nama_item ?? '-'}</Td>
                <Td>{val?.satuan_pakai ?? '-'}</Td>
                <Td className="text-right">{parseFloat(val?.qty_permintaan_produksi ?? '0')}</Td>
                <Td className="text-right">{parseFloat(val?.qty_telah_ditransfer ?? '0')}</Td>
                <Td style={{ display: action === 'VIEW' ? 'none' : '' }}>
                  {parseFloat(val?.qty_permintaan_produksi) === parseFloat(val?.qty_telah_ditransfer)
                    ? (<ActionButton size="sm" variant="success" disable={true} text={<AiOutlineCheck />} />)
                    : (<ActionButton size="sm" text={<IoAddOutline />} onClick={() => setModal({ ...modal, show: true, data: val })} />)
                  }
                </Td>
              </Tr>
            ))
            : <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          }
        </TBody>
      </Table>
      <ModalTransfer
        action="CREATE"
        data={modal?.data ?? {}}
        dataHistory={dataHistory}
        show={modal?.show}
        onHide={() => setModal({ ...modal, show: false })}
        refresh={refresh}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
      />
    </>
  )
}
