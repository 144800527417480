import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import * as Yup from "yup"
import { DateConvert } from 'utilities'
import Axios from "axios"
import { FilterModal, DatePicker, SelectSearch } from 'components'
import { LaporanStockUpdateApi } from 'api'

const ModalFilter = ({ show, setShow, dropdown, setDropdown, data, setData }) => {
    const [loading, setLoading] = useState({ jenis: false, item_buaso: false })
    const filter = data?.filter

// FORM VALUES
    const formInitialValues = {
      tanggal: filter?.tanggal ?? null,
      id_kelompok: filter?.id_kelompok ?? null,
      id_jenis: filter?.id_jenis ?? null,
      id_item_buaso: filter.id_item_buaso ?? "",
      id_gudang: filter.id_gudang ?? "0",
    }
    const formValidationSchema = Yup.object().shape({
        tanggal: Yup.date().typeError("Masukkan Tanggal").required("Masukkan Tanggal"),
        id_kelompok: Yup.string().required("Pilih Kelompok").nullable(),
        id_jenis: Yup.string().required("Pilih Jenis").nullable(),
        id_item_buaso: Yup.string().required("Pilih Item Bahan").nullable(),
        id_gudang: Yup.string().required("Pilih Gudang").nullable(),
    })
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0
    
        if (checkActive) {
          setData({
            filter: { ...values, active: true }
          })
        } else {
          setData({ ...data, filter: { ...values, active: false } })
        }
        setShow(false)
    }

// HANDLE SUBMIT
    const onResetButtonClick = () => {
        setData(prev => ({
          ...prev,
          filter: {
            tanggal: undefined,
            id_item_buaso: undefined,
            id_kelompok: undefined,
            id_jenis: undefined,
            id_gudang: undefined,
          }
        }))
    
        setShow(false)
    }

// FETCH DROPDOWN DATA
    const getJenisData = (value) => {
        setLoading({ jenis: true, item_buaso: true })
    
        LaporanStockUpdateApi.getDropdown({ tipe: "jenis", id_kelompok: value })
          .then((res) => setDropdown(prev => ({ ...prev, jenis: res.data.data?.map((val) => ({ ...val, value: val.id_jenis, label: val.nama_jenis })), })))
          .catch(() => setDropdown(prev => ({ ...prev, jenis: [], })))
          .finally(() => setLoading({ jenis: false, item_buaso: false }))
    }
    const getItemData = (value) => {
        setLoading(prev => ({ ...prev, item_buaso: true }))
    
        LaporanStockUpdateApi.getDropdown({ tipe: "item_buaso", id_jenis: value })
          .then((res) => setDropdown(prev => ({ ...prev, item_buaso: res.data.data?.map((val) => ({ ...val, value: val.id_item_buaso, label: val.nama_item })) })))
          .catch(() => setDropdown(prev => ({ ...prev, item_buaso: [] })))
          .finally(() => setLoading(prev => ({ ...prev, item_buaso: false })))
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}>
            
            {({values, errors, touched, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick()}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker
                        label="Tanggal"
                        type="date"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        selected={values?.tanggal}
                        onChange={(selectedDate) => { setValues({ ...values, tanggal: selectedDate })}}
                        error={!!(errors.tanggal) && !!(touched.tanggal) && true}
						errorText={!!(errors.tanggal && touched.tanggal) && errors.tanggal}
                    />
                    <SelectSearch
                        label="Kelompok"
                        placeholder="Pilih Kelompok"
                        defaultValue={values.id_kelompok ? dropdown.kelompok.find(val => val.value === values.id_kelompok) : null}
                        option={dropdown?.kelompok ?? []}
                        onChange={(val) => {
                            setValues({ ...values, id_kelompok: val.value, id_jenis: "", id_item_buaso: "" })
                            getJenisData(val.value)
                        }}
                        error={errors.id_kelompok && touched.id_kelompok && true}
						errorText={errors.id_kelompok && touched.id_kelompok && errors.id_kelompok}
                    />
                    <SelectSearch
                        label="Jenis"
                        placeholder="Pilih Jenis"
                        defaultValue={values.id_jenis ? dropdown.jenis.find(val => val.value === values.id_jenis) : null}
                        option={dropdown.jenis ?? []}
                        onChange={(val) => {
                          setValues({ ...values, id_jenis: val.value, id_item_buaso: "" })
                          getItemData(val.value)
                        }}
                        isDisabled={Boolean(values.id_kelompok) ? false : true}
                        loading={loading.jenis}
                        error={errors.id_jenis && touched.id_jenis && true}
						errorText={errors.id_jenis && touched.id_jenis && errors.id_jenis}
                    />
                    <SelectSearch
                        label="Item Bahan"
                        placeholder="Pilih bahan"
                        defaultValue={values.id_item_buaso ? dropdown.item_buaso.find(val => val.value === values.id_item_buaso) : null}
                        option={dropdown.item_buaso ?? []}
                        onChange={(val) => setValues({ ...values, id_item_buaso: val.value })}
                        isDisabled={Boolean(values.id_kelompok) ? false : true}
                        loading={loading.item_buaso}
                        error={Boolean(errors.id_item_buaso && touched.id_item_buaso) && true}
                        errorText={errors.id_item_buaso ?? ""}
                    />
                    <SelectSearch
                        key={values.id_gudang}
                        label="Gudang"
                        placeholder="Pilih gudang"
                        defaultValue={values.id_gudang ? dropdown.gudang.find(val => val.value === values.id_gudang) : null}
                        option={dropdown.gudang ?? []}
                        onChange={(val) => setValues({ ...values, id_gudang: val.value })}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;
