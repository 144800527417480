// React
import { useState, useEffect } from "react"
import { useHistory, useParams, useLocation } from "react-router-dom"

// Component
import { Card, Modal, Row, Col } from "react-bootstrap"
import {
  Alert, BackButton, DataStatus, ActionButton, CRUDLayout
} from "components"

// Icon
import { IoAlertCircleOutline } from 'react-icons/io5'

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// API
import Axios from 'axios'
import { SuratJalanApi } from 'api'

// Page Component
import {
  InfoSection, InfoDOSection, FormSection
} from '../Section'

const UbahSuratJalan = ({setNavbarTitle}) => {
  const history = useHistory()
  const {id} = useParams()
  const location = useLocation()
  const infoValue = location?.state?.infoValue?.tgl_surat_jalan
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dataArmada, setDataArmada] = useState([])
  const [dataPetugas, setDataPetugas] = useState([])
  const [dataGudang, setDataGudang] = useState([])
  const [dataStakeholder, setDataStakeholder] = useState([])
  const [formRendered, setFormRendered] = useState(0)
  const [showModalQty, setShowModalQty] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const mappingDataTable = data => data.map(val => {
    return {
      id_surat_jalan_detail: val.id_surat_jalan_detail ?? null,
      id_delivery_order_detail: val.id_delivery_order_detail ?? null,
      id_delivery_order: val.id_delivery_order ?? null,
      kode_item: val.kode_barang ?? "-",
      nama_item: val.nama_barang ?? "-",
      satuan: val.nama_satuan ?? "",
      satuan_surat_jalan: val.satuan_jual ?? "",
      qty_do: parseFloat(val.qty_do ?? 0),
      qty_terkirim: parseFloat(val.qty_terkirim ?? 0),
      qty_sisa_do: parseFloat(val.qty_sisa_do ?? 0),
      qty_surat_jalan: parseFloat(val.qty_sj ?? 0),
      qty_satuan_pakai: parseFloat(val.qty_satuan_pakai ?? 0),
      stok_gudang: parseFloat(val.qty_stok_gudang ?? 0),
      qty_menunggu_persetujuan: parseFloat(val.qty_menunggu_persetujuan ?? 0),
    }
  })
  const mappingDataPetugas = data => data.map(val => {
    return {
      label: val.nama_supir,
      value: val.id_supir
    }
  })
  const mappingDataArmada = data => data.map(val => {
    return {
      label: val.nama_kendaraan,
      value: val.id_kendaraan
    }
  })
  const mappingDataGudang = data => data.map(val => {
    return {
      label: val.nama_gudang,
      value: val.id_gudang
    }
  })

  const getInitialData = (date) => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      SuratJalanApi.getSingle({ id_surat_jalan: id, tanggal: infoValue }),
      SuratJalanApi.getDropdownPetugas(),
      SuratJalanApi.getDropdownArmada(),
      SuratJalanApi.getGudang(),
    ])
      .then(Axios.spread((dataDO, petugas, armada, gudang) => {
        const getData = dataDO.data.data
        const getDataStakeholder = dataDO.data.data.stakeholder
        const getPetugas = petugas.data.data
        const getArmada = armada.data.data
        const getGudang = gudang.data.data

        const mapDataTable = mappingDataTable(getData.detail ?? [])
        const mapDataPetugas = mappingDataPetugas(getPetugas ?? [])
        const mapDataArmada = mappingDataArmada(getArmada ?? [])
        const mapDataGudang = mappingDataGudang(getGudang ?? [])

        setDataStakeholder(getDataStakeholder ?? [])
        setDataInfo(getData)
        setDataTable(mapDataTable)
        setDataPetugas(mapDataPetugas)
        setDataArmada(mapDataArmada)
        setDataGudang(mapDataGudang)
        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const formInitialValues = {
    tanggal: dataInfo.tgl_surat_jalan,
    nomor: dataInfo.no_surat_jalan,
    petugas_pengiriman: dataInfo.id_karyawan,
    armada_pengiriman: dataInfo.id_kendaraan,
    catatan_surat_jalan: dataInfo.catatan_surat_jalan,
    is_konsinyasi: dataInfo.is_konsinyasi,
    id_gudang_tujuan_konsinyasi: dataInfo.id_gudang_tujuan_konsinyasi ?? null,
  }
  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
    petugas_pengiriman: Yup.string().required("Pilih petugas pengiriman"),
    armada_pengiriman: Yup.string().required("Pilih armada pengiriman"),
  })
  const formSubmitHandler = (values, {setSubmitting}) => {
    const tableValues = dataTable.map(val => {
      return {
        // id_delivery_order_detail: val.id_delivery_order_detail,
        // id_delivery_order: val.id_delivery_order,
        id_surat_jalan_detail: val.id_surat_jalan_detail,
        qty_surat_jalan: val.qty_surat_jalan,
        qty_satuan_jual: val.qty_satuan_pakai
      }
    })
    const finalValues = {
      id_surat_jalan: id,
      id_supir: values.petugas_pengiriman,
      id_petugas_gudang: values.petugas_pengiriman,
      id_kendaraan: values.armada_pengiriman,
      id_gudang_tujuan_konsinyasi: values.id_gudang_tujuan_konsinyasi,
      is_konsinyasi: values.is_konsinyasi,
      tgl_surat_jalan: values.tanggal,
      no_surat_jalan: values.nomor,
      catatan_surat_jalan: values.catatan_surat_jalan,
      detail: tableValues,
    }

    SuratJalanApi.update(finalValues)
      .then(() => {
        history.push("/inventory/transaksi/surat-jalan", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil diubah!"
          }
        })
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal diubah!"
        })
      })
      .finally(() => setSubmitting(false))
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Surat Jalan")

    // eslint-disable-next-line
  }, [])

  const ModalQty = () => (
    <Modal 
      show={showModalQty}
      onHide={() => setShowModalQty(false)}
    >
      <Modal.Header closeButton>
        <b className="text-danger">Peringatan</b>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="mb-3">
          <IoAlertCircleOutline size={75} className="mb-2 text-danger" /><br />
          <b>Qty. Surat Jalan Tidak Valid!</b><br />
          <small><b>QTY. SURAT JALAN</b> tidak boleh lebih dari <b>QTY. SISA DO</b> dan tidak boleh bernilai 0!</small>
        </p>
      </Modal.Body>
    </Modal>
  )

  const CatatanApprovalCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Surat Jalan</b>
        </Card.Header>
        <Card.Body>
            {dataStakeholder.length > 1
              ? <Row>
                  {dataStakeholder.map((val, index) => index !== 0 &&
                    val.status_approval !== "PEN" && (
                      <Col sm>
                        <InfoItem
                          title1={
                            val.status_approval === "VER" || val.status_approval === "REV"
                              ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                              : val.status_approval === "APP"
                                ? "Pengesah"
                                : "Di Tolak Oleh"
                          }
                          value1={val.nama_karyawan ?? "-"}
                          title2="Catatan"
                          value2={val.catatan ?? "-"}
                        />
                      </Col>
                    )
                  )}
                </Row>
              : <div className="d-flex justify-content-center">
                  <DataStatus text="Tidak ada catatan" />
                </div>
            }
        </Card.Body>
      </Card>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/inventory/transaksi/surat-jalan", { ...location?.state })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div className="font-weight-bold mb-2">Ubah Data Surat Jalan</div>
      <Card>
        <Card.Body>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() => setAlertConfig({
              ...alertConfig,
              show: false
            })}
          />
          {fetchingStatus.loading
            ? <DataStatus loading text="Memuat . . ." />
            : fetchingStatus.success
              ? <Formik
                  initialValues={formInitialValues}
                  validationSchema={formValidationSchema}
                  onSubmit={formSubmitHandler}
                >
                  {formik => (
                    <form onSubmit={formik.handleSubmit}>
                      <InfoSection data={dataInfo} />
                      <InfoDOSection data={dataInfo} />

                      <FormSection 
                        formik={formik} 
                        formRendered={formRendered}
                        fetchingStatus={fetchingStatus}
                        setFormRendered={setFormRendered}
                        dataArmada={dataArmada}
                        dataPetugas={dataPetugas}
                        dataGudang={dataGudang}
                        dataTable={dataTable}
                        setDataTable={setDataTable}
                        getInitialData={getInitialData}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        type='update'
                      />

                      <div className="d-flex justify-content-end">
                        <ActionButton 
                          type="submit"
                          className="mt-5"
                          variant="success"
                          text="Ubah"
                          loading={formik.isSubmitting}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              : <DataStatus text="Data gagal dimuat" />
          }
        </Card.Body>
        <ModalQty />
      </Card>
      {/* {!fetchingStatus.loading && fetchingStatus.success && <CatatanApprovalCard />} */}
    </CRUDLayout>
  )
}

export default UbahSuratJalan