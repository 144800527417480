import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  DatePicker,
  Input,
  CreateButton,
  SelectSearch,
  TextArea,
  UpdateButton,
  ReadButton,
  Approval,
  ThFixed,
  ApprovalStatusButton,
  FilterButton,
  TdFixed
} from "components";
import { DateConvert, TableNumber } from "utilities";
import { MutasiAntarGudangApi } from "api";
import Axios from "axios";
import { ModalFilterMAG } from "./comps";

const MutasiAntarGudang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPenerimaanSuratJalan, setDataPenerimaanSuratJalan] = useState([]);
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.mag?.filter?.active,
      tgl_mutasi_mulai: location?.state?.mag?.filter?.tgl_mutasi_mulai,
      tgl_mutasi_selesai: location?.state?.mag?.filter?.tgl_mutasi_selesai,
      item_barang: location?.state?.mag?.filter?.item_barang,
      gudang_asal: location?.state?.mag?.filter?.gudang_asal,
      gudang_tujuan: location?.state?.mag?.filter?.gudang_tujuan,
      satuan: location?.state?.mag?.filter?.satuan,
      status_approval: location?.state?.mag?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.mag?.filter?.page ?? "1",
      dataLength: location?.state?.mag?.filter?.dataLength ?? "10",
      totalPage: location?.state?.mag?.filter?.totalPage ?? "1",
      dataCount: location?.state?.mag?.filter?.dataCount ?? "0",
    }
  })
  const [processedData, setProcessedData] = useState({});
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    type: "",
    show: false,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    MutasiAntarGudangApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_mutasi_mulai: dataFilter?.filter?.tgl_mutasi_mulai,
      tgl_mutasi_selesai: dataFilter?.filter?.tgl_mutasi_selesai,
      item_barang: dataFilter?.filter?.item_barang,
      gudang_asal: dataFilter?.filter?.gudang_asal,
      gudang_tujuan: dataFilter?.filter?.gudang_tujuan,
      satuan: dataFilter?.filter?.satuan,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then(( data ) => {
        setDataPenerimaanSuratJalan(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getModalData = (id) => {
    MutasiAntarGudangApi.getSingle({
      id_mutasi_antar_gudang: id,
    })
      .then(({ data }) => {
        setDataStakeholder(data.data);
      })
      .catch((err) => {
        // setAlertConfig({
        //   show: true,
        //   variant: "danger",
        //   text: "Data gagal dimuat!",
        // });
      })
      .finally(() => {});
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Mutasi Antar Gudang");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_mutasi_mulai,
    dataFilter?.filter?.tgl_mutasi_selesai,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.gudang_asal,
    dataFilter?.filter?.gudang_tujuan,
    dataFilter?.filter?.satuan,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {
    const DataTable = () => {
      const ItemBarangCollapse = ({ data }) => {
        const [isReadMoreClick, setisReadMoreClick] = useState(false);
    
        return (
          <>
            <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
              {data.map((brg, index) => {
                if (isReadMoreClick) {
                  return <li key={index} index={index}>{`${brg.nama_item} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
                } else {
                  return index <= 1 && <li key={index} index={index}>{`${brg.nama_item} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
                }
              })}
            </ul>
            {data.length > 2 ? (
              <div
                className="text-primary"
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  // fontWeight: "bold",
                }}
                onClick={() => { setisReadMoreClick((prev) => !prev)}}>
                {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
              </div>
            ): ("")}
          </>
        );
      }
      const checkStatus = (stat) => {
        const data = stat ? stat.toUpperCase() : "";
        const convert = data.substring(0, 3);
  
        if (convert === "APP")
          return {
            variant: "outline-success",
            label: "APPROVED",
          };
        if (convert === "VER")
          return {
            variant: "outline-success",
            label: "VERIFIED",
          };
        if (convert === "REV")
          return {
            variant: "outline-warning",
            label: "REVISION",
          };
        if (convert === "REJ")
          return {
            variant: "outline-danger",
            label: "REJECTED",
          };
        return {
          variant: "outline-secondary",
          label: "PENDING",
        };
      }
      return (
        <>
          <b>List Data Mutasi Antar Gudang</b>
          <Table>
            <thead>
              <tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Mutasi</ThFixed>
                <Th>Item Barang</Th>
                <Th width={200}>Gudang Asal</Th>
                <Th width={150}>Gudang Tujuan</Th>
                <Th width={100}>Qty. Mutasi</Th>
                <Th width={100}>Satuan</Th>
                <ThFixed>Status Approval</ThFixed>
              </tr>
            </thead>
            <tbody>
              {dataPenerimaanSuratJalan.map((val, index) => (
                <tr key={index}>
                  <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <ReadButton
                        onClick={() => {
                          setProcessedData(val);
                          getModalData(val.id_mutasi_antar_gudang);
                          setModalConfig({
                            show: true,
                            type: "detail",
                          });
                        }}
                      />
                      {val.status_approval === "REV" ? (
                        <UpdateButton
                          onClick={() => {
                            setProcessedData(val);
                            setModalConfig({
                              show: true,
                              type: "update",
                            });
                          }}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_mutasi_antar_gudang ? DateConvert(new Date(val.tgl_mutasi_antar_gudang)).defaultDMY : "-"}</div>
                    <div className="text-left">{val.no_mutasi_antar_gudang ?? "-"}</div>
                  </TdFixed>
                  <Td>{val.nama_item ?? "-"}</Td>
                  <Td>{val.nama_gudang_asal ?? "-"}</Td>
                  <Td>{val.nama_gudang_tujuan ?? "-"}</Td>
                  <Td className="text-right">{val.qty_mutasi ?? "-"}</Td>
                  <Td>{val.nama_satuan ?? "-"}</Td>
                  <TdFixed>
                    <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                      {checkStatus(val.status_approval).label}
                    </ApprovalStatusButton>
                  </TdFixed>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      );
    };

    if (!dataPenerimaanSuratJalan || dataPenerimaanSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    );
  };

  const PageModal = () => {
    const today = DateConvert(new Date()).default;
    const [dropdown, setDropdown] = useState({
      bahan: [],
      gudang: [],
    });
    const [modalFetchingStatus, setModalFetchingStatus] = useState({
      loading: false,
      success: true,
    });

    const mappingDropdownGudang = (data) => {
      return data.map((val) => new Object({ value: val.id_gudang, label: val.nama_gudang }));
    };
    const mappingDropdownBahan = (data) => {
      return data.map(
        (val) =>
          new Object({
            value: val.id_item_buaso,
            label: val.nama_item,
            kode_item: val.kode_item,
            dimensi: val.dimensi,
            pj: val.pj,
            lb: val.lb,
            tb: val.tb,
          })
      );
    };
    const getInitialModalData = () => {
      setModalFetchingStatus({
        loading: true,
        success: false,
      });

      Axios.all([
        MutasiAntarGudangApi.getDropdown({ tipe: "bahan" }),
        MutasiAntarGudangApi.getDropdown({ tipe: "gudang" }),
      ])
        .then(
          Axios.spread((bahan, gudang) => {
            const mapDropdownBahan = mappingDropdownBahan(bahan.data.data);
            const mapDropdownGudang = mappingDropdownGudang(gudang.data.data);

            setDropdown({
              bahan: mapDropdownBahan,
              gudang: mapDropdownGudang,
            });
            setModalFetchingStatus({
              loading: false,
              success: true,
            });
          })
        )
        .catch((err) => {
          setModalFetchingStatus({
            loading: false,
            success: false,
          });
        });
    };

    useEffect(() => {
      getInitialModalData();

      return () => {};
    }, []);

    const ModalContent = () => {
      const formInitialValues = {
        tgl: processedData.tgl_mutasi_antar_gudang ?? today,
        no: processedData.no_mutasi_antar_gudang ?? "",
        id_item_buaso: processedData.id_item_buaso ?? "",
        id_gudang_asal: processedData.id_gudang_asal ?? "",
        id_gudang_tujuan: processedData.id_gudang_tujuan ?? "",
        stock_update: 0,
        qty_mutasi: parseInt(processedData.qty_mutasi) || 0,
        catatan_mutasi: processedData.catatan_mutasi ?? "",
      };

      const formValidationSchema = Yup.object().shape({
        tgl: Yup.string().required("Pilih tanggal mutasi antar gudang"),
        no: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
        id_item_buaso: Yup.string().required("Pilih item bahan"),
        id_gudang_asal: Yup.string().required("Pilih gudang asal"),
        id_gudang_tujuan: Yup.string().required("Pilih gudang tujuan"),
        qty_mutasi: Yup.number().required("Masukkan qty. mutasi"),
      });

      const formSubmitHandler = (values, { setSubmitting }) => {
        if (
          parseFloat(values.stock_update) > 0 &&
          parseFloat(values.qty_mutasi) <= parseFloat(values.stock_update)
        ) {
          const finalValues = {
            tgl_mutasi_antar_gudang: values.tgl,
            id_item_buaso: values.id_item_buaso,
            id_gudang_asal: values.id_gudang_asal,
            id_gudang_tujuan: values.id_gudang_tujuan,
            qty_mutasi: values.qty_mutasi,
            catatan_mutasi: values.catatan_mutasi,
          };

          if (modalConfig.type === "update") {
            finalValues.id_mutasi_antar_gudang = processedData.id_mutasi_antar_gudang;
          }

          MutasiAntarGudangApi.save(modalConfig.type, finalValues)
            .then(() => {
              setAlertConfig({
                show: true,
                variant: "primary",
                text: "Data berhasil disimpan!",
              });
            })
            .catch(() => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: "Data gagal disimpan!",
              });
            })
            .finally(() => {
              setProcessedData({});
              setModalConfig({
                show: false,
                type: "",
              });
              getInitialData();
            });
        } else {
          setSubmitting(false);
          window.alert(
            "Tidak dapat menyimpan data (Stock Update kosong / Qty. Mutasi melebihi Stock Update )"
          );
        }
      };

      const FormSection = ({ formik, dropdown }) => {
        const [isNomorLoading, setIsNomorLoading] = useState(false);
        const { values, errors, touched, setValues, handleChange, setFieldValue } = formik; 

        const decimalConvert = (value) => {
          const newValue = value.toString().replace(/[^0-9\.]/g, "");
          const convert = newValue.substring(0, 7);

          return convert;
        };

        const getNomorHandler = (tanggal) => {
          setIsNomorLoading(true);
          MutasiAntarGudangApi.getNomor({ tanggal })
            .then((val) => {
              setFieldValue("tgl", tanggal);
              setFieldValue("no", val?.data?.data ?? "-");
            })
            .catch(() => {
              window.alert("Gagal memuat nomor");
              setValues({
                tgl: "",
                no: "",
              });
            })
            .finally(() => setIsNomorLoading(false));
        };

        const getStockUpdate = () => {
          MutasiAntarGudangApi.getStockUpdate({
            id_bahan: values.id_item_buaso,
            id_gudang: values.id_gudang_asal,
          })
            .then((res) => setFieldValue("stock_update", res?.data?.data?.jumlah ?? 0))
            .catch((err) => window.alert("Gagal memuat stock update"));
        };

        useEffect(() => {
          if (modalConfig.type === "create") {
            getNomorHandler(today);
          }
        }, []);

        useEffect(() => {
          //fetch getStockUpdate jika dropdown
          if (values.id_item_buaso !== "" && values.id_gudang_asal !== "") {
            getStockUpdate();
          }
          return () => {};
        }, [values.id_item_buaso, values.id_gudang_asal]);

        return (
          <>
            <Row>
              <Col md>
                <DatePicker
                  label="Tgl. Mutasi"
                  selected={values.tgl ? new Date(values.tgl) : ""}
                  placeholderText="Pilih tanggal"
                  onChange={(date) => {
                    if (modalConfig.type === "create") {
                      getNomorHandler(DateConvert(new Date(date)).default);
                    }
                    if (modalConfig.type === "update") {
                      setFieldValue("tgl", DateConvert(new Date(date)).default);
                    }
                  }}
                  error={Boolean(errors.tgl) && touched.tgl}
                  errorText={Boolean(errors.tgl) && touched.tgl && errors.tgl}
                />
              </Col>
              <Col md>
                <Input
                  label="No. Mutasi"
                  placeholder={
                    isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"
                  }
                  value={isNomorLoading ? "Memuat nomor . . ." : values.no}
                  error={Boolean(errors.no) && touched.no}
                  errorText={Boolean(errors.no) && touched.no && errors.no}
                  readOnly
                />
              </Col>
            </Row>
            <SelectSearch
              label="Item Bahan"
              name="id_item_buaso"
              placeholder="Pilih item bahan"
              defaultValue={dropdown.bahan.find((e) => e.value == values.id_item_buaso) ?? ""}
              onChange={(val) => setFieldValue("id_item_buaso", val.value)}
              option={dropdown.bahan}
              error={Boolean(errors.id_item_buaso) && touched.id_item_buaso}
              errorText={
                Boolean(errors.id_item_buaso) && touched.id_item_buaso && errors.id_item_buaso
              }
            />
            <Row>
              <Col md={8}>
                <SelectSearch
                  label="Gudang Asal"
                  name="id_gudang_asal"
                  placeholder="Pilih gudang asal"
                  defaultValue={dropdown.gudang.find((e) => e.value == values.id_gudang_asal) ?? ""}
                  onChange={(val) => setFieldValue("id_gudang_asal", val.value)}
                  option={dropdown.gudang}
                  error={Boolean(errors.id_gudang_asal) && touched.id_gudang_asal}
                  errorText={
                    Boolean(errors.id_gudang_asal) &&
                    touched.id_gudang_asal &&
                    errors.id_gudang_asal
                  }
                />
              </Col>
              <Col md={4}>
                <Input
                  label="Stock Update"
                  name="stock_update"
                  value={values.stock_update}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <SelectSearch
                  label="Gudang Tujuan"
                  name="id_gudang_tujuan"
                  placeholder="Pilih gudang tujuan"
                  defaultValue={
                    dropdown.gudang.find((e) => e.value == values.id_gudang_tujuan) ?? ""
                  }
                  onChange={(val) => setFieldValue("id_gudang_tujuan", val.value)}
                  option={dropdown.gudang}
                  error={Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan}
                  errorText={
                    Boolean(errors.id_gudang_tujuan) &&
                    touched.id_gudang_tujuan &&
                    errors.id_gudang_tujuan
                  }
                />
              </Col>
              <Col md={4}>
                <Input
                  label="Qty. Mutasi"
                  id="qty_mutasi"
                  name="qty_mutasi"
                  value={values.qty_mutasi}
                  onChange={(val) => {
                    const value = decimalConvert(val.target.value);
                    setFieldValue("qty_mutasi", value);
                  }}
                  error={errors.qty_mutasi && touched.qty_mutasi && true}
                  errorText={errors.qty_mutasi}
                />
              </Col>
            </Row>
            <TextArea
              label="Catatan Mutasi"
              type="text"
              name="catatan_mutasi"
              value={values.catatan_mutasi}
              onChange={handleChange}
              rows={2}
              error={errors.catatan_mutasi && touched.catatan_mutasi && true}
              errorText={errors.catatan_mutasi}
            />
          </>
        );
      };

      // CONTENT KETIKA MELIHAT DETAIL
      const FormDetail = () => {
        const InfoItem = ({ title, text, line }) => (
          <div className="mb-2">
            <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
              {title}
            </small>
            <div
              className="text-dark"
              style={{
                fontWeight: 500,
              }}
            >
              {text ?? "-"}
            </div>
            {line && <hr className="my-2" />}
          </div>
        );

        return (
          <Col>
            <InfoItem
              title="Tgl. Mutasi"
              text={DateConvert(new Date(processedData.tgl_mutasi_antar_gudang)).detail}
            />
            <InfoItem title="No. Mutasi" text={processedData.no_mutasi_antar_gudang} />
            <InfoItem title="Item Bahan" text={processedData.nama_item} />
            <InfoItem title="Gudang Asal" text={processedData.nama_gudang_asal} />
            <InfoItem title="Gudang Tujuan" text={processedData.nama_gudang_tujuan} />
            <InfoItem title="Qty. Mutasi" text={processedData.qty_mutasi} />
            <InfoItem title="Catatan Mutasi" text={processedData.catatan_mutasi} />
          </Col>
        );
      };

      return (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                {modalConfig.type === "detail" ? (
                  <>
                    <FormDetail />
                    <Approval data={dataStakeholder} />
                  </>
                ) : (
                  <FormSection formik={formik} dropdown={dropdown} />
                )}
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type !== "detail" && (
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    loading={formik.isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      );
    };

    return (
      <Modal
        size="md"
        show={modalConfig.show}
        onHide={() => {
          setProcessedData({});
          setModalConfig({
            show: false,
            type: "",
          });
        }}
      >
        <Modal.Header closeButton>
          <b className="text-capitalize">
            {`${
              modalConfig.type === "create"
                ? "Tambah"
                : modalConfig.type === "update"
                ? "Ubah"
                : "Detail"
            } Data Mutasi Antar Gudang`}
          </b>
        </Modal.Header>
        {modalFetchingStatus.loading ? (
          <DataStatus loading text="Memuat data . . ." />
        ) : modalFetchingStatus.success ? (
          <ModalContent />
        ) : (
          <DataStatus text="Data gagal dimuat" />
        )}
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="md"
            className="col"
            onClick={() => setModalConfig({ show: true, type: "create" })}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

      {/* Modal */}
      {modalConfig.show && <PageModal />}

      {modalFilter === true && (
        <ModalFilterMAG
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default MutasiAntarGudang;
