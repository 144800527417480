import { Col, Row } from "react-bootstrap"
// import { useFormikContext } from "formik"

const TabKonversiDetail = ({ data }) => {
    // const { values } = useFormikContext()
    const thouSep = ".";
    const decSep = ",";
    const toMoney = (num) => {return (Math.round(num * 100) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/[,.]/g, function (m) {return m === ',' ? thouSep : decSep})}

    return (
        <div>
            <Row>
                <Col lg={6}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <small>Satuan Beli</small>
                                </td>
                                <td />
                                <td>
                                    <small>Satuan Pakai</small>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <b>1 {data.satuan_beli ?? '-'}</b>
                                </td>
                                <td style={{ width: '25px' }} className="text-center"> = </td>
                                <td>
                                    <b>{data.nilai_konversi_beli_pakai ? parseFloat(data?.nilai_konversi_beli_pakai).toLocaleString().replace(/,/g, '.') : '-'} {data.satuan_pakai ?? '-'}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <small>Satuan Jual</small>
                                </td>
                                <td />
                                <td>
                                    <small>Satuan Pakai</small>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <b>1 {data.satuan_jual ?? '-'}</b>
                                </td>
                                <td style={{ width: '25px' }} className="text-center"> = </td>
                                <td>
                                    <b>{data.nilai_konversi_jual_pakai ? parseFloat(data?.nilai_konversi_jual_pakai).toLocaleString().replace(/,/g, '.') : '-'} {data.satuan_pakai ?? '-'}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
    )
}

export default TabKonversiDetail